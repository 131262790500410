import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

interface InfoProps {
    popup: string;
}

const Info: React.FC<InfoProps> = ({ popup }) => (
    <Tooltip title={popup} placement="top">
        <span className="mx-2" style={{ opacity: 0.5 }}>
            <InfoOutlined />
        </span>
    </Tooltip>
);

export default Info;
