import { CCard, CContainer } from '@coreui/react-pro';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppHeader } from '../../../components';
import { Widget } from '../../../components/WidgetRenderer';
import Loader from '../../../components/loader/Loader';
import { staticRoutes } from '../../../configuration/constants';
import useAppDispatch from '../../../services/redux/useAppDispatch';
import useAppSelect from '../../../services/redux/useAppSelect';
import useSendMessage from '../../../services/websocket/useWebSocket';
import { FieldInterface, WidgetInterface } from '../../../types';

/**
 * @param apiPath The path to the API endpoint that will return the metadata for the form. e.g. localhost:8080/api/registration/admin
 */
export interface RegistrationProps {
  apiPath: string;
  layout?: {
    sidenav: boolean;
    header: {
      narrow: boolean;
    };
    footer: boolean;
  };
  title?: string;
}

export interface RegistrationServerResponse {
  title?: string;
  fields: FieldInterface[];
}

const SystemInitialization = (props: RegistrationProps) => {
  const { layout, title } = props;
  const { sendMessage } = useSendMessage();
  const { t } = useTranslation();
  useAppDispatch();
  const [data, setData] = useState<RegistrationServerResponse | null>(null);
  const views = useAppSelect('views');

  useEffect(() => {
    // Get metadata from server

    if (views[staticRoutes.systemInitialization]) {
      const { title, fields } = views[staticRoutes.systemInitialization];

      setData({ title, fields });
    }
  }, [JSON.stringify(views)]); // TODO: construct better dependency array

  useEffect(() => {
      sendMessage(staticRoutes.systemInitialization, 'GET')
        ?.then(data => {
          if (data) {
            const { title, fields } = data.body;
            setData({ title, fields });
          }
        });
  }, []);

  const Header = layout?.header ? AppHeader : React.Fragment;

  const formWidget: WidgetInterface = {
    _type: 'form',
    _id: staticRoutes.systemInitialization,
    _content: data?.fields ?? [],
    _url: staticRoutes.systemInitialization,
  };

  const submitWidget: WidgetInterface = {
    _type: 'submit',
    _url: staticRoutes.systemInitialization,
    _id: staticRoutes.systemInitialization,
    _content: Array.isArray(data?.fields) ? data?.fields?.map(f => `${staticRoutes.systemInitialization}.${f?._key}`) ?? [] : [],
    _label: t('Submit'),
  };

  return (
    <>
      <>{loading && <Loader type="linear" />}</>
      <Header narrow={!!layout?.header.narrow} />
      <CContainer className="py-5">
        <h1>{t(data?.title) ?? t(title) ?? ''}</h1>
        <CCard className="p-5">
          {data?.fields && (
            <Stack direction="column" spacing={2}>
              <Widget widget={formWidget} />
              <Widget widget={submitWidget} />
            </Stack>
          )}
        </CCard>
      </CContainer>
    </>
  );
};

export default SystemInitialization;
