import React, { PropsWithChildren } from 'react';
import useAppSelect from '../services/redux/useAppSelect';

const RtlAdjustHorizontal = ({ children }: PropsWithChildren) => {
    const isRtl = useAppSelect('rtl');

    return <div key={isRtl ? 'rtl-sidebar' : 'ltr-sidebar'}>{children}</div>;
};

export default React.memo(RtlAdjustHorizontal);
