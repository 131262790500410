import React from 'react';

interface StackProps {
    direction: 'row' | 'column';
    style?: React.CSSProperties;
    children?: React.ReactNode;
    onClick?: () => void;
}

const Stack = (props: StackProps) => {
    const { direction, style, ...rest } = props;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: direction === 'row' ? 'row' : 'column',
                gap: '2rem',
                alignItems: 'center',
                height: '100%',
                ...style,
            }}
            {...rest}
        >
            {props.children}
        </div>
    );
};

export default Stack;
 
