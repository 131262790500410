import { PageInterface } from '../../types';

export const getRoute = (page: PageInterface) => {
    const route = page._url ?? page.url ?? page._route ?? page.route;

    if (route?.startsWith('/')) {
        return route;
    } else {
        return `/${route}`;
    }
};
