import { t } from 'i18next';
import { PASSWORD_LEN } from '../configuration/constants';
import { FieldInterface } from '../types';

const generateNumberErrorMessage = (sizeType: string, numbers: number[]): string => {
    switch (sizeType) {
        case 'h':
            return t('Number must be greater than or equal to {{target}}', {target: numbers[0]});
        case 'l':
            return t('Number must be less than or equal to {{target}}', {target: numbers[0]});
        case 'b':
            return t('Number must be between {{min}} and {{max}}, inclusive', {min: numbers[0], max: numbers[1]});
        default:
            return '';
    }
};

const validateFieldSize = (value: unknown, field: FieldInterface): string => {
    // Only proceed if fieldSize is defined
    if (field._size) {
        const sizeType = field._size[0];
        const numbers = field._size.slice(1).split(',').map(Number);

        switch (field._type) {
            case 'text':
            case 'link':
            case 'password':
                // For text-based inputs, we're looking at the length of the value
                const textLength = String(value).length;
                if (sizeType === 'h' && textLength < numbers[0]) {
                    return t(`Text must be at least ${numbers[0]} characters long`);
                } else if (sizeType === 'l' && textLength > numbers[0]) {
                    return t(`Text must be no more than ${numbers[0]} characters long`);
                } else if (sizeType === 'b' && (textLength < numbers[0] || textLength > numbers[1])) {
                  return t('BaseInput must be between {{min}} and {{max}} characters long', { min: numbers[0], max: numbers[1] });
                }
                break;
            case 'number':
                const numberValue = Number(value);
                if ((sizeType === 'h' && numberValue < numbers[0]) || (sizeType === 'l' && numberValue > numbers[0]) || (sizeType === 'b' && (numberValue < numbers[0] || numberValue > numbers[1]))) {
                    return generateNumberErrorMessage(sizeType, numbers);
                }
                break;
            case 'date':
            case 'time':
                // For date and time, you might need to compare against specific dates or times
                // Consider converting value to Date object and comparing as needed
                // This example is simplistic and might need adjustments
                // return t(`Date/Time value does not meet requirements`);
                break;
            // Add more case blocks for other types as necessary
        }
    }
    return '';
};



/**
 *
 * @param value Any value
 * @param field metadata for the field to test against
 * @returns a string with the error message, or empty string if valid
 */
export const getFieldError = (value: unknown, field: FieldInterface) => {
    if (!value) {
        return field._required ? `${t(field._label ?? field._key)} ${t('is required')}` : '';
    }

    let result = '';

    switch (field._type) {
        case 'email':
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            result = emailRegex.test(value) ? '' : t('Email should be of the format: johndoe@gmail.com');
            break;
        case 'time':
            // Basic check for time format (HH:MM), does not validate for correct time ranges
            const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
            result = timeRegex.test(String(value)) === null ? t('Please enter a time in HH:MM format') : '';
            break;

        case 'link':
            // Simple URL validation
            const urlRegex = /^(http|https):\/\/[^ "]+$/;
            result = urlRegex.test(String(value)) ? '' : t('Please enter a valid URL');
            break;

        case 'password':
            // Example: Passwords must be at least 8 characters long. Customize as needed.
            result = String(value).length >= PASSWORD_LEN ? '' : t('Password must be at least 8 characters');
            break;

        case 'file':
            const expectedFileType = field._subtype;
            const actualFileType = String(value)?.name;
            result = actualFileType === expectedFileType ? '' : t('File type not supported');
            break;
    }

    if (!result) {
        result = validateFieldSize(value, field);
    }

    return result;
};
