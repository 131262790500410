import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState, RootState } from './globalStateManager';

const selectAppByKey = (key) =>
    createSelector(
        (state: RootState) => state.app,
        (app: AppState) => app[key]
    );

const useAppSelect = <K extends keyof AppState>(key: K): AppState[K] => {
    const selector = useMemo(() => selectAppByKey(key), [key]);
    return useSelector((state: RootState) => selector(state));
};

export default useAppSelect;
