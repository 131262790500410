import * as muiIcons from '@mui/icons-material';
import React from 'react';

interface IconProps {
    name: string;
    sx?: React.CSSProperties;
    onClick?: () => void;
    children?: React.ReactNode;
    color?: React.CSSProperties['color'];
}

const Icon = (props: IconProps) => {
    const { name } = props;

    const MuiIcon = muiIcons[name];

    const baseProps = {
        width: '20px',
        height: '20px',
        color: props.color ?? 'unset',
    };

    if (!name) return null;

    // An icon could be MuiIcon, base64, or initials
    if (MuiIcon) {
        return <MuiIcon {...props} sx={baseProps} />;
    } else if (name?.base64) {
        return <img src={name.base64} alt="icon" style={baseProps} {...props} />;
    } else if (name?.startsWith('data:iamge')) {
        return <img src={name} alt="icon" style={baseProps} {...props} />;
    } else {
        const initials = name
            .split(' ')
            .map(word => word[0].toUpperCase())
            .join('');

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '38px',
                    height: '38px',
                    borderRadius: '50%',
                    backgroundColor: '#ccc',
                    color: 'black',
                    padding: '0.5rem',
                    fontWeight: 700,
                    marginLeft: '-0.33rem',
                    marginRight: '-0.15rem',
                    marginTop: '-1.5px',
                    ...props.sx,
                }}
            >
                {initials}
            </div>
        );
    }
};

export default Icon;
