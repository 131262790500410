import { cilMap } from '@coreui/icons';
import { CButton } from '@coreui/react-pro';
import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getGeocode, getUserLocation } from '../../../utils/google-map';
import defaultCoords from '../../google-map/defaultCoords';
import Stack from '../../layout-util/stack';
import Loader from '../../loader/Loader';
import StyleWrapper from '../../style-wrapper';
import AddressSearch from './AddressSearch';
import Openable from './openable';

export type Location = {
    lat: number;
    lng: number;
};

type Center = Location;

export const getMap = (elemId: string, mapOptions: google.maps.MapOptions) => new google.maps.Map(document.getElementById(elemId) as HTMLElement, mapOptions);

const Map: React.FC<unknown> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [forceClose, setForceClose] = React.useState(false);
    const [center, setCenter] = React.useState<Center | null>(null);
    const [address, setAddress] = React.useState<string | null>(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (forceClose && !center) {
            setLoading(true);
            getUserLocation()
                .then((result: Location) => {
                    setCenter(result);
                })
                .catch(() => {
                    console.log('could not get user location, using default coords:', defaultCoords);
                    setCenter(defaultCoords);
                })
                .finally(() => setLoading(false));
        }
    }, [forceClose]);

    useEffect(() => {
        if (center) {
            setLoading(true);
            getGeocode(center)
                .then((result) => {
                    if (result?.length) {
                        setAddress(result[0]?.formatted_address);
                    }
                })
                .catch((e) => {
                    console.log('could not get address', e);
                    console.error(e);
                    setAddress(null);
                })
                .finally(() => setLoading(false));
        }
    }, [center?.lat, center?.lng]);

    const mapRef = useRef<GoogleMap | null>(null);

    return (
        <Openable forceClose={forceClose} icon={cilMap} value={address ?? props?.value} label={t('Choose Location')}>
            <Stack direction="column">
                <div style={{ marginTop: '2rem' }}>
                    <AddressSearch
                        onPlaceChanged={(place) => {
                            const location = place?.geometry?.location;
                            const lat = location?.lat();
                            const lng = location?.lng();

                            if (lat && lng) {
                                setCenter({ lat, lng });
                            }
                        }}
                    />
                    {loading ? <Loader type="linear" /> : <></>}
                </div>

                <StyleWrapper opacity={center ? 1 : 0} height="480px" width="100%">
                    <GoogleMap
                        mapContainerStyle={{ height: '480px', width: '100%' }}
                        ref={mapRef}
                        id="map"
                        zoom={15}
                        center={center ?? defaultCoords}
                        onDragEnd={() => {
                            const _center = mapRef?.current?.state?.map?.getCenter();
                            const lat = _center?.lat();
                            const lng = _center?.lng();
                            if (lat && lng) {
                                if (center?.lat !== lat || center?.lng !== lng) {
                                    setCenter({ lat, lng });
                                }
                            }
                        }}
                        onLoad={() => setLoading(false)}
                        onTilesLoaded={() => setLoading(false)}
                    >
                        {center && <Marker position={center} />}
                    </GoogleMap>
                </StyleWrapper>

                <CButton
                    disabled={!address}
                    style={{ marginBottom: '2rem' }}
                    color={address ? 'success' : 'secondary'}
                    onClick={() => {
                        if (props.onChange && center) {
                            props.onChange(address ?? '');
                            if (forceClose) {
                                setForceClose(false);
                            }
                            setForceClose(true);
                        }
                    }}
                >
                    {address ? (
                        <span>
                            {t('Save')} <u>{address}</u>
                        </span>
                    ) : (
                        <span>{t('Please choose an address') + props?.config?.required ? '*' : '*'}</span>
                    )}
                </CButton>
            </Stack>
        </Openable>
    );
};

export default Map;
