import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { capitalize } from '@mui/material';
import _ from 'lodash';
import { PropsWithChildren } from 'react';
import { dismountForm, updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { FieldInterface, WidgetInterface } from '../../types';
import { singular } from '../../utils';
import { Widget } from '../WidgetRenderer';

interface TableModalProps {
    // Props Here
    id: string;
}

const TableModal = (props: PropsWithChildren<TableModalProps>) => {
    const { id } = props; // Will be used to target the table's current data from storage

    const runtime = useAppSelect('runtime');

    const { mode, columns, modal, editRow } = runtime[id] ?? {};

    const dispatch = useAppDispatch();

  const formWidgetId = 'form-widget';

    const handleClose = () => {
        dispatch(updateRuntimeData(`${id}.mode`, 'view'));
        dispatch(updateRuntimeData(`${id}.editRow`, {}));
        dispatch(dismountForm(formWidgetId));
    };

    const fieldsWithDefaultValues = columns?.map((col: FieldInterface) => ({ ...col, ...(editRow ? { _value: editRow?.[col._key] } : {}) }));

    const formWidget: WidgetInterface = {
        _id: formWidgetId,
        _content: fieldsWithDefaultValues,
        _type: 'form',
    };

    const submitWidget: WidgetInterface = {
        _id: id,
        _content: columns?.map(column => `${formWidgetId}.${column._key}`) ?? [],
        _type: 'submit',
        _subtype: mode,
        _label: 'Submit',
        _url: _.get(runtime, `${id}.postUrl`),
    };

    return (
        <CModal size="xl" onClose={handleClose} visible={mode === 'CREATE' || mode === 'UPDATE'}>
            <CModalHeader>
                <CModalTitle>{singular(capitalize(modal?.title ?? ''))} </CModalTitle>
            </CModalHeader>

            <CModalBody>
                <Widget widget={formWidget} />
                <Widget widget={submitWidget} />
            </CModalBody>
        </CModal>
    );
};
export default TableModal;
