import { Box, CircularProgress } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { performOCR } from '../../services/ocr/getLicensePlate';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { FieldInterface, FieldType, SelectionOption } from '../../types';
import { useSource } from '../../utils';
import { getFieldError } from '../../validators/validateField';
import MapWithUserLocation from '../google-map';
import ButtonInput from './field-types/ButtonInput';
import DateRangePicker from './field-types/DateRangePicker';
import File from './field-types/File';
import Map from './field-types/Map';
import Radio from './field-types/Radio';
import SelectableList from './field-types/SelectableList';
import Selection from './field-types/Selection';
import Signature from './field-types/Signature';
import BaseInput from '../input/BaseInput';
import TextArea from './field-types/TextArea';
import RepairCostField from './field-types/RepairCostField';
import Date from './field-types/Date';
import Blank from '../../views/blank/Blank';

export interface FieldComponentProps {
    field: FieldInterface;
    options: SelectionOption[];
    placeholder: string;
    config: FieldInterface;
    onChange: (e: React.ChangeEvent) => void;
    icon?: string;
    onDateChange: (date: Date | null, formatedDate?: string | undefined) => void;
    value: string;
    footer?: boolean;
    closeOnSelect?: boolean;
    confirmButton?: boolean;
    firstDayOfWeek?: number;
    onEndDateChange: (a: unknown, b: unknown) => void;
    onStartDateChange: (a: unknown, b: unknown) => void;
    id: string;
    disabled: boolean;
    shouldFocus: boolean;
    ind: number;
}

const visibleProps = {};
const notVisibleProps = {
    sx: {
        backgroundColor: 'rgba(0,0,0,0.75)',
        opacity: '0.35',
        pointerEvents: 'none' /* Prevents mouse clicks */,
        color: '#a0a0a0' /* Dim text color */,
        borderRadius: '8px',
    },
};

// TODO: These should be moved elsewhere
export const validProps = {
    border: '2px solid green',
    outline: 'none',
    borderRadius: '4px',
};
export const invalidProps = {
    border: '2px solid crimson',
    outline: 'none',
    borderRadius: '4px',
};

// Assuming FieldType is an enum or a set of string constants
const fieldTypeToComponent: Record<FieldType, React.FC<FieldComponentProps>> = {
    text: BaseInput,
    textarea: TextArea,
    email: BaseInput,
    password: BaseInput,
    number: BaseInput,
    date: Date,
    'date-range': DateRangePicker,
    selection: Selection,
    map: Map,
    location: MapWithUserLocation,
    link: BaseInput,
    file: File,
    regex: BaseInput,
    radio: Radio,
    signature: Signature,
    'selectable-list': SelectableList,
    button: ButtonInput,
    repairCostField: RepairCostField,
    autocomplete: Blank,
    // ... other field types
};

export const formStatePrefix = '__form_state__';

const Field = (props: { field: FieldInterface; id: string }) => {
    const runtime = useAppSelect('runtime');
    const [loading, setLoading] = React.useState(true);

    const [visible, setVisible] = React.useState(true);
    const [calculatedValue, setCalculatedValue] = React.useState('');

    const listeningSource = props.field?._source?.value;
    const listeningVisible = props.field._prerequisite?.value;

    const { value } = useSource(props.field._source);

    const id = props.id ?? props._id ?? props.field._key;

    React.useEffect(() => {
        if (props.field._value) {
            dispatch(updateRuntimeData(id, props.field._value, 'override'));
            dispatch(updateRuntimeData(`${formStatePrefix}.${id}.validated`, 'true', 'override'));
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        const source = _.get(runtime, listeningSource);
        const transform = props.field._source?.transform;
        if (transform === 'ocr') {
            performOCR(source?.base64).then(res => {
                setCalculatedValue(res?.digits);
                setLoading(false);
            });
        } else {
            setCalculatedValue(source);
            setLoading(false);
        }
    }, [_.get(runtime, listeningSource)]);

    React.useEffect(() => {
        if (!props.field._prerequisite) {
            setVisible(true);
            return;
        }
        if (_.get(runtime, listeningVisible)) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [_.get(runtime, listeningVisible)]);

    React.useEffect(() => {
        // Check if the field has predefined data source
        setCalculatedValue(_.get(runtime, id) ?? props.field._source?.defaultValue ?? props.field._value);
        const errorMessage = getFieldError(_.get(runtime, id), props.field);
        dispatch(updateRuntimeData(`${formStatePrefix}.${id}.errorMessage`, errorMessage, 'merge'));
    }, [_.get(runtime, id)]);

    const dispatch = useAppDispatch();
    const Component = fieldTypeToComponent[props.field._type];

    if (!Component) {
        console.warn('Field of type', props.field._type, 'is not yet supported.');
        return null;
    }

    const { field } = props;

    const onChange = (e: React.ChangeEvent) => {
        const result = e?.target?.value ?? e?.target?.checked ?? e?.target?.files[0] ?? e;
        dispatch(updateRuntimeData(id, result, 'override'));
        dispatch(updateRuntimeData(`${formStatePrefix}.${id}.validated`, 'true', 'merge'));
    };

    const errorMessage = _.get(runtime, `${formStatePrefix}.${id}`)?.errorMessage;
    const validated = _.get(runtime, `${formStatePrefix}.${id}.validated`) === 'true';

    return (
        <Box {...(visible ? visibleProps : notVisibleProps)} padding={0} margin={0}>
            <Box
                className="form-control"
                sx={{ position: 'relative', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
                {...(validated && errorMessage && invalidProps)}
                {...(validated && errorMessage === '' && validProps)}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Component
                        field={field}
                        options={value}
                        placeholder={field.placeholder}
                        config={field}
                        onChange={onChange}
                        icon={field._icon}
                        onDateChange={onChange}
                        value={calculatedValue}
                        footer
                        closeOnSelect
                        confirmButton
                        firstDayOfWeek={0}
                        onEndDateChange={(_: unknown, b: unknown) => onChange(b)}
                        onStartDateChange={(_: unknown, b: unknown) => onChange(b)}
                        id={id}
                        disabled={visible}
                        shouldFocus={props.ind === 0}
                        ind={props.ind}
                    />
                )}
            </Box>
            {validated && <span style={{ color: 'red', fontSize: '0.75em' }}>{errorMessage}</span>}
        </Box>
    );
};

export default Field;
