import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { formatDate } from './date';

export const downloadTable = async (data: Record<string, any>[], type: 'excel' | 'pdf') => {
    if (type === 'pdf') {
        const doc = new jsPDF();
        autoTable(doc, {
            html: 'table',
            pageBreak: 'auto',
            styles: { overflow: 'linebreak', cellWidth: 'wrap', cellPadding: 1, fontSize: 6 },
            columnStyles: { text: { cellWidth: 'auto' } },
            horizontalPageBreak: true,
            horizontalPageBreakRepeat: 'employeeID',
        });
        doc.save('data.pdf');
    } else if (type === 'excel') {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        const borderStyle = 'thick';
        const cellSize = 16;

        // Function to set cell alignment and color for booleans and numbers
        const formatCell = (cell, originalValue) => {
            if (typeof originalValue === 'boolean') {
                cell.value = originalValue ? '✓' : '✗';
                cell.alignment = { horizontal: 'center' };
                cell.font = { color: { argb: originalValue ? 'FF00FF00' : 'FFFF0000' }, size: cellSize };
            } else if (typeof originalValue === 'number') {
                cell.alignment = { horizontal: 'center' };
            }
        };

        // Function to approximate the width of a cell's content
        const approximateCellWidth = (value) => {
            if (typeof value === 'boolean') {
                return 5;
            } else if (value !== null && value !== undefined) {
                return value.toString().length;
            }
            return 0;
        };

        // Function to set border for a cell
        const applyBorder = (cell, sides) => {
            const border = {};
            sides.forEach((side) => {
                border[side] = { style: borderStyle, color: { argb: 'FF99CCFF' } };
            });
            cell.border = border;
        };

        // Set headers and format
        const headers = Object.keys(data[0]);
        worksheet.columns = headers.map((header) => ({ header, key: header }));
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, size: cellSize };
            cell.alignment = { horizontal: 'center' };
            applyBorder(cell, ['top']);
        });

        // Add and format data rows
        data.forEach((row) => {
            const newRow = worksheet.addRow(row);
            newRow.eachCell((cell, colNumber) => {
                const ind = worksheet.columns[colNumber - 1].key ?? 0;
                formatCell(cell, row[ind]);
            });
        });

        // Adjust column widths
        worksheet.columns.forEach((column) => {
            let maxColumnLength = Math.max(approximateCellWidth(column.header) * 2, 10);
            if (column.eachCell)
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const cellLength = approximateCellWidth(cell.value);
                    maxColumnLength = Math.max(cellLength, maxColumnLength);

                    if (Number(cell.col) === 1) {
                        applyBorder(cell, ['left']);
                    } else if (Number(cell.col) === worksheet.columnCount) {
                        applyBorder(cell, ['right']);
                    }
                });
            column.width = maxColumnLength;
        });

        // Apply border to corner cells and table bounds
        const lastRow = worksheet.rowCount;
        const lastColumn = worksheet.columnCount;

        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
                row.eachCell((cell) => {
                    applyBorder(cell, ['top']);
                });
            } else if (rowNumber === lastRow) {
                row.eachCell((cell) => {
                    applyBorder(cell, ['bottom']);
                });
            }
        });

        applyBorder(worksheet.getCell(1, 1), ['top', 'left']);
        applyBorder(worksheet.getCell(1, lastColumn), ['top', 'right']);
        applyBorder(worksheet.getCell(lastRow, 1), ['bottom', 'left']);
        applyBorder(worksheet.getCell(lastRow, lastColumn), ['bottom', 'right']);

        // Write to a buffer and save
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = 'data-' + formatDate(new Date()) + '.xlsx';
        saveAs(blob, fileName);
    }
};
