import { CLoadingButton } from '@coreui/react-pro';
import _ from 'lodash';
import { PropsWithChildren, useEffect, useState } from 'react';
import useAppSelect from '../../services/redux/useAppSelect';
import useSendMessage from '../../services/websocket/useWebSocket';
import { FieldInterface, WidgetInterface } from '../../types';
import { formatDate, useSource } from '../../utils';
import Icon from '../mui-icon/Icon';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formStatePrefix } from '../form/Field';
import useAppDispatch from '../../services/redux/useAppDispatch';
import { updateRuntimeData } from '../../services/redux/globalStateManager';

interface SubmitProps {
    // Props Here
    widget: WidgetInterface;
}

const deepEqual = (a: unknown, b: unknown) => {
    return a === b || JSON.stringify(a) === JSON.stringify(b);
};

export const getValueAsType = (value, fieldMetaData: FieldInterface) => {
    const { _type } = fieldMetaData;
    const { _subtype } = fieldMetaData;

    switch (_type) {
        case 'number':
        case 'telephone':
            return parseInt(value);
        case 'boolean':
            return Boolean(value);
        case 'text':
        case 'chars':
        case 'textarea':
        case 'email':
        case 'password':
        case 'radio':
        case 'location':
        case 'link':
            return value;
        case 'date':
        case 'time':
        case 'dateAndTime':
            return formatDate(value);
        case 'selection':
            if (_subtype === 'multiple') {
                return value.split(',').map(item => item.trim());
            } else {
                return value;
            }
        case 'file':
            return value;
        case 'image':
        case 'video':
        case 'document':
        case 'audio':
            return value;
        default:
            return value;
    }
};

const Submit = (props: PropsWithChildren<SubmitProps>) => {
    const runtime = useAppSelect('runtime');
    const dispatch = useAppDispatch();
    const { sendMessage } = useSendMessage();
    const { widget } = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { value } = useSource(widget._icon);

    useEffect(() => {
        let hasErrors = false;
        let hasChanged = false;
        widget._content?.forEach(id => {
            const fieldMetaData: FieldInterface = _.get(runtime, `${formStatePrefix}.${id}`);
            if ((fieldMetaData?.validated && fieldMetaData?.errorMessage) || fieldMetaData?.errors) {
                hasErrors = true;
            }

            // If fields are required they must pass validation
            if (fieldMetaData?._required && !fieldMetaData?.validated) {
                hasErrors = true;
            }

            if (!fieldMetaData?.defaultValue && _.get(runtime, id)) {
                hasChanged = true;
            }
            if (fieldMetaData?.defaultValue && !deepEqual(fieldMetaData?.defaultValue, _.get(runtime, id) ?? '')) {
                hasChanged = true;
            }
        });

        // Will disable the button if there are errors or if nothing has changed
        setIsDisabled(hasErrors || !hasChanged);
    }, [runtime]);

    const handleClick = () => {
        const data = widget._content?.reduce((acc, id) => {
            const _id = id?.split('.')[1];
            const stringVal = _.get(runtime, id);
            const fieldMetaData: FieldInterface = _.get(runtime, `__form_state__.${id}`);
            const valueAsType = stringVal ? getValueAsType(stringVal, fieldMetaData) : '';

            acc[_id] = valueAsType;

            return acc;
        }, {});
      setLoading(true);
      sendMessage(props.widget._url ?? '', 'CREATE', data)
        ?.then(result => {
          setLoading(false);
        })
        ?.catch(error => {
          setLoading(false);
          console.error(error);
          setError(error);
        });
      dispatch(updateRuntimeData(props.widget?._id + '.mode', 'view', 'override'));
    };
    return (
        <Stack spacing={2} direction="column">
            {error ? <span style={{ color: 'red' }}>{t('Error: ') + error.description + ` (${error.code})`}</span> : null}

            <CLoadingButton style={{ width: 'fit-content' }} disabled={isDisabled} loading={loading} onClick={handleClick}>
                {widget._label && <span>{t(widget._label)}</span>}
                {widget._icon && value && <Icon name={value} />}
            </CLoadingButton>
        </Stack>
    );
};

export default Submit;
