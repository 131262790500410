import SettingsPanel from '../../../components/settings-panel/SettingsPanel';
import DefaultLayout from '../../layout/DefaultLayout';

const SettingsPage = () => (
    <DefaultLayout>
        <SettingsPanel />
    </DefaultLayout>
);

export default SettingsPage;
