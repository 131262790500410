import { CFooter } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { staticRoutes } from '../configuration/constants';
import useAppSelect from '../services/redux/useAppSelect';
import ContentNavbarAdjuster from './layout-util/ContentNavbarAdjuster';

const AppFooter = () => {
    const { t } = useTranslation();
    const isRtl = useAppSelect('rtl');

    return (
        <ContentNavbarAdjuster>
            <CFooter>
                <div className={`${isRtl ? 'ms-auto' : 'me-auto'}  px-5`}>
                    <span className="mx-1">{t('Powered by')}</span>
                    <a href={staticRoutes.dashboard} rel="noopener noreferrer">
                        {t('BETec')}
                    </a>
                </div>
            </CFooter>
        </ContentNavbarAdjuster>
    );
};

export default React.memo(AppFooter);
