import { CCol, CContainer, CDropdownDivider, CImage, CRow } from '@coreui/react-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWebSocket from '../../services/websocket/useWebSocket';
import Section from '../layout-util/Section';
import Stack from '../layout-util/stack';
import { WidgetProps } from '../page/Page';

export interface GalleryData {
    caption: string;
    subcaption: string;
    src: string;
    alt: string;
}

const Gallery: React.FC<WidgetProps> = () => {
    const [file, setFile] = React.useState<Blob | MediaSource | null>(null);
    const [data, setData] = useState<GalleryData[]>([]);

    const { t } = useTranslation();

    const { sendMessage } = useWebSocket();

    useEffect(() => {
        sendMessage('url', 'GET')?.then((responseData) => {
          if (responseData && Array.isArray(responseData)) setData(responseData);
        });
    }, []);

    return (
        <CContainer style={{}}>
            <Section>
                <Stack direction="row" style={{ flexWrap: 'wrap' }}>
                    {data?.map((item: GalleryData) => (
                        <CRow key={item.src}>
                            <CCol sm={6} lg={3}>
                                <h3 style={{ whiteSpace: 'nowrap' }}>{item.caption}</h3>
                                <p style={{ whiteSpace: 'nowrap' }}>{item.subcaption}</p>
                                <CImage src={item.src} alt={item.alt} />
                            </CCol>
                        </CRow>
                    ))}
                </Stack>
            </Section>

            <CDropdownDivider />

            {/** File input */}
            <Section>
                <CRow>
                    <CCol sm={6} lg={3}>
                        <h3 style={{ whiteSpace: 'nowrap' }}>{t('File Input')}</h3>
                        <p style={{ whiteSpace: 'nowrap' }}>{t('Upload a file')}</p>
                        <input type="file" accept="image/jpeg, .jpg, .jpeg" onChange={(e) => setFile(e?.target?.files ? e?.target?.files[0] : null)} />
                    </CCol>
                </CRow>
            </Section>

            {file && (
                <Section>
                    <CRow>
                        <CCol sm={6} lg={3}>
                            <h3 style={{ whiteSpace: 'nowrap' }}>{t('File Preview')}</h3>
                            <p style={{ whiteSpace: 'nowrap' }}>{t('Preview of the file')}</p>
                            <CImage src={URL.createObjectURL(file)} fluid />
                        </CCol>
                    </CRow>
                </Section>
            )}
        </CContainer>
    );
};

export default Gallery;
