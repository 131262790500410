import { CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setRtl } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import Icon from '../mui-icon/Icon';

interface Language {
    code: string;
    icon: string;
    name: string;
}

interface LanguageDropdownProps {
    languages: Language[];
}

const LanguageDropdown = (props: LanguageDropdownProps) => {
    const { languages } = props;
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const handleLangPopover = (e: React.ChangeEvent<HTMLDivElement>) => {
        const chosenLang = e?.target?.dataset?.value;

        if (chosenLang) {
            i18n.changeLanguage(chosenLang);

            window.setTimeout(() => {
                i18next.changeLanguage(chosenLang);
            }, 150);

            if (chosenLang) {
                // Remove ltr class and add rtl class
                document.body.className = document.body.className.replace('ltr', '');
                document.body.className = document.body.className + ' rtl';

                document.documentElement.dir = 'rtl';

                dispatch(setRtl(true));
            } else {
                // Remove rtl class and add ltr class
                document.body.className = document.body.className.replace('rtl', '');
                document.body.className = document.body.className + ' ltr';

                document.documentElement.dir = 'ltr';
                dispatch(setRtl(false));
            }
        }
    };

    return (
        <CDropdown dir="ltr" component="div" variant="nav-item">
            <CDropdownToggle component="div" caret={false}>
                <Icon name="Public" />
            </CDropdownToggle>
            <CDropdownMenu
                component="div"
                className="pt-0"
                style={{
                    left: '50%',
                    right: '50%',
                    transform: 'translate(-50%, 0) !important',
                }}
            >
                <CDropdownHeader component="div" className="bg-light fw-semibold py-2">
                    {t('languages')}
                </CDropdownHeader>
                {languages?.map(lang => (
                    <CDropdownItem component="div" href="#" key={lang.code} onClick={handleLangPopover} data-value={lang.code} style={{ cursor: 'pointer' }}>
                        {lang.icon}
                        <span className="mx-1"></span>
                        {t(lang.name)}
                    </CDropdownItem>
                ))}
            </CDropdownMenu>
        </CDropdown>
    );
};

export default LanguageDropdown;
