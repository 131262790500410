import CIcon from '@coreui/icons-react';
import { CCloseButton } from '@coreui/react-pro';
import React, { PropsWithChildren } from 'react';
import useResponsiveStyles from '../../../../utils/useResponsiveStyles';
import Stack from '../../../layout-util/stack';
import StyleWrapper from '../../../style-wrapper';
import Label from '../../Label';
import ClickOutsideDetecter from '../ClickOutsideDetecter';

interface OpenableProps {
    icon: string[];
    value: string | number | boolean | React.ReactNode;
    label: string;
    forceClose: boolean;
}

const Openable = (props: PropsWithChildren<OpenableProps>) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.forceClose) setOpen(false);
    }, [props.forceClose]);

    const responsiveMapWrapper = useResponsiveStyles({
        zIndex: 999,
        position: 'fixed',
        width: {
            mobile: '90%',
            tablet: '400px',
            laptop: '500px',
            desktop: '650px',
        },
        height: {
            mobile: 'fit-content',
        },
        backgroundColor: '#2d2d2d',
        top: {
            mobile: '50%',
        },
        borderRadius: '4px',
        boxShadow: '0 0 0 0.12rem rgba(12, 12, 12, 0.9)',
        left: {
            mobile: '50%',
        },
        transform: {
            mobile: 'translate(-50%, -50%)',
        },
        display: open ? 'block' : 'none',
        transition: 'all 0.5s ease',
    });

    return (
        <StyleWrapper position="relative" display="flex" justifyContent="space-around" alignItems="center" minHeight="49px">
            <ClickOutsideDetecter
                callback={() => {
                    console.log('clicked outside');
                }}
            >
                <div style={responsiveMapWrapper}>
                    <CCloseButton
                        className="float-end"
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '14px',
                        }}
                        onClick={() => setOpen(false)}
                    />
                    {props.children}
                </div>

                <Stack direction="row">
                    {props.value}

                    <Stack
                        direction="row"
                        onClick={() => setOpen((prevOpen) => !prevOpen)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {!props.value && <Label style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>{props.label}</Label>}
                        <CIcon icon={props.icon} />
                    </Stack>
                </Stack>
            </ClickOutsideDetecter>
        </StyleWrapper>
    );
};

export default Openable;
