import { AppAside, AppHeader } from '../../components/index';

type NarrowHeaderLayoutProps = {
    children?: JSX.Element | JSX.Element[];
};

const NarrowHeaderLayout = (props: NarrowHeaderLayoutProps): JSX.Element => (
    <>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
            <AppHeader narrow={true} />
            <div className="body flex-grow-1 px-3">{props.children}</div>
        </div>
        <AppAside />
    </>
);

export default NarrowHeaderLayout;
