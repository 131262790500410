import React from 'react';

// Extend LabelProps with standard label properties
interface LabelProps extends React.HTMLProps<HTMLLabelElement> {
    required?: boolean;
}

const Label: React.FC<LabelProps> = (props) => {
    const { required, children, ...labelProps } = props;

    return (
        <label {...labelProps}>
            {children} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
    );
};

export default Label;
