import { CImage, CModal, CModalBody, CModalHeader } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/mui-icon/Icon';
import { ViewContext } from '../../../components/page/Page';
import { changeTheme, setRtl } from '../../../services/redux/globalStateManager';
import useAppDispatch from '../../../services/redux/useAppDispatch';

export const paragraphStyle = { marginBottom: '12px' };
export const newlineStyle = { marginBottom: '2px' };
export const buttonStyle = {
    color: 'white',
    backgroundColor: 'black',
    fontWeight: 'bold',
    fontSize: '32px',
    width: '150px',
    outline: 'none',
    borderWidth: '0px',
    borderRadius: '8px',
};

const ClientView = () => {
    const { t, i18n } = useTranslation();
    const [showVideoModal, setShowVideoModal] = React.useState(false);
    const [showImageModal, setShowImageModal] = React.useState(false);

    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(changeTheme('light-theme'));
        dispatch(setRtl(true));
        i18n.changeLanguage('he');

        document.documentElement.dir = 'rtl';

        window.setTimeout(() => {
            document.documentElement.classList = '';
            dispatch(changeTheme('light-theme'));
            dispatch(setRtl(true));
        }, 50);
    }, []);

    const { setValidity } = React.useContext(ViewContext);
    React.useEffect(() => {
        setValidity(true);
    }, []);

    return (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 16px 16px 16px',
                textAlign: 'center',
            }}
        >
            <CImage src="/union.png" width="100%" alt={'union'} />
            <p style={newlineStyle}></p>
            {/* Price Offer BaseInput */}
            <h2 style={newlineStyle}>{t('Price Offer')}</h2>
            {/* Description BaseInput */}
            <p style={paragraphStyle}>{t('Hello, attached below is an offer regarding to your garage request.')}</p>
            {/* Ticket Number */}
            <p style={paragraphStyle}>
                {t('Ticket')}: <strong>{t('12345')}</strong>
            </p>
            {/* Phone Number */}
            <p style={paragraphStyle}>
                {t('Phone Number')}: <strong>{t('555-1234')}</strong>
            </p>
            {/* Rectangle Buttons */}
            <div style={{ ...newlineStyle, fontSize: '14px', display: 'flex', gap: '1rem' }}>
                <button onClick={() => setShowVideoModal(true)} style={{ ...buttonStyle, fontSize: '22px' }}>
                    <img width={45} height={25} src="/unionVideoFirstFrame.png" alt="video preview" />
                    <Icon name="PhotoCameraFront" />
                    <> </> {t('Video')}
                </button>
                <button onClick={() => setShowImageModal(true)} style={{ ...buttonStyle, fontSize: '22px', padding: '10px 14px' }}>
                    <img width={25} height={25} src="/unionCar.png" alt="Image preview" />
                    <Icon name="Image" />
                    <> </> {t('Image')}
                </button>
            </div>
            <div style={paragraphStyle}></div>
            {/* Video Modal */}
            <CModal alignment="center" visible={showVideoModal} onClose={() => setShowVideoModal(false)} size="lg">
                <CModalHeader></CModalHeader>
                <CModalBody>
                    {/* Replace with your video component or embed */}
                    <video autoPlay width="100%" height="100%" controls>
                        <source src="/unionCarVideo.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </CModalBody>
            </CModal>

            {/* Image Modal */}
            <CModal alignment="center" visible={showImageModal} onClose={() => setShowImageModal(false)} size="lg">
                <CModalHeader></CModalHeader>
                <CModalBody>
                    {/* Replace with your image */}
                    <img src="/unionCar.png" alt="Modal" style={{ width: '100%', height: 'auto' }} />
                </CModalBody>
            </CModal>
            {/* Additional BaseInput Paragraph */}
            <p
                style={{
                    ...paragraphStyle,
                    border: '1px ridge white',
                    padding: '30px 40px',
                    borderRadius: '8px',
                    textAlign: 'right',
                }}
            >
                {t('Your additional text goes here...')}
            </p>
            {/* Colored Background BaseInput */}
            <div
                style={{
                    ...paragraphStyle,
                    borderRadius: '8px',
                    backgroundColor: '#f0ad4e',
                    textAlign: 'center',
                    color: 'black',
                    padding: '15px 20px',
                    border: '1px ridge white',
                    fontSize: '24px',
                }}
            >
                {t('Repair Cost') + ': 5000 שח'}
            </div>
            <p style={newlineStyle}></p>
            {/* Footer with Buttons */}
            <div style={{ ...newlineStyle, display: 'flex', justifyContent: 'center', gap: '15px', marginTop: 'auto' }}>
                <button style={{ ...buttonStyle, color: 'green' }}>
                    <Icon name="Check" />
                    <span className="mx-2" style={{ color: 'green' }}>
                        {t('Confirm')}
                    </span>
                </button>
                <button style={{ ...buttonStyle, color: 'red' }}>
                    <Icon name="Close" />

                    <span className="mx-2" style={{ color: 'red' }}>
                        {t('Reject')}
                    </span>
                </button>
            </div>
            {/* Call a Representative Button */}
            <a
                href={'tel:056-999098'}
                style={{
                    ...newlineStyle,
                    ...buttonStyle,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '315px',
                    textDecoration: 'none',
                    color: 'yellow',
                }}
            >
                <Icon name="Phone" />
                <span className="mx-2" style={{ color: 'yellow' }}>
                    {t('Call a Representative')}
                </span>
            </a>
            <div style={{ textAlign: 'center', paddingBottom: '24px' }}></div>
        </div>
    );
};

export default ClientView;
