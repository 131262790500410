type PerformOCR = {
    text: string;
    digits: string;
};

export const performOCR = async (base64Image: string): Promise<PerformOCR | null> => {
    if (!process.env.REACT_APP_OCR_KEY) {
        console.error('No OCR key found. Please set REACT_APP_OCR_KEY in .env file.');
        return null;
    }
    try {
        const base64ImageData = base64Image.split(',')[1]; // Remove "data:image/jpeg;base64,"

        // Make a POST request to the OCR.space API
        const response = await fetch('https://gg5cja3xb6.execute-api.eu-central-1.amazonaws.com/prod/detect-plate', {
            method: 'POST',
            body: JSON.stringify({
                apikey: process.env.REACT_APP_OCR_KEY,
                base64Image: base64ImageData,
                filetype: 'JPG',
            }),
        });

        // Parse the response JSON
        const data = await response.json();
        // Extract the recognized text

        return data;
    } catch (error) {
        console.error('Error performing OCR:', error);
        return null;
    }
};
