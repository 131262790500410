export const loadApi = async (): Promise<HTMLScriptElement> =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');
        const apikey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
        if (!apikey) {
            return reject('No google maps api key found. Please set REACT_APP_GOOGLE_MAPS_KEY in .env file.');
        }
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apikey}&libraries=places`;
        script.async = true;

        // Define the callback function to be called when the script is loaded
        script.onload = () => {
            console.log('Google maps api loaded');
            resolve(script);
        };

        script.onerror = e => {
            console.warn('Error loading google maps api', e);
            reject(e);
        };

        // Append the script element to the component's DOM
        document.body.appendChild(script);
        document.documentElement.appendChild(script);
    });
