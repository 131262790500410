/**
 * Formats a given date into a YYYY-MM-DD string.
 * @param date - The date to be formatted
 * @returns The formatted date string
 */
export function formatDate(date: Date | string) {
    date = new Date(date);
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1; // getMonth() returns a zero-based index
    let day: number | string = date.getDate();

    // Add leading zeros to month and day if they are single-digit
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
}
