import useAppSelect from '../../services/redux/useAppSelect';
import Blank from '../../views/blank/Blank';
import Loader from '../loader/Loader';

const AppLoader = () => {
    const loader = useAppSelect('loader');

    const ErrLoader =
        loader.type === 'toast-err' ? <Loader type="toast-err" description={loader.description} status={loader.status} /> : <Blank />;

    const LinearLoader = loader.type === 'linear' ? <Loader type="linear" /> : <Blank />;

    return (
        <>
            {LinearLoader}
            {ErrLoader}
        </>
    );
};

export default AppLoader;
