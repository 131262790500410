import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { CButton } from '@coreui/react-pro';
import { cilChevronTop } from '@coreui/icons';
import Openable from './openable';
import { useTranslation } from 'react-i18next';
import StyleWrapper from '../../style-wrapper';
import Stack from '../../layout-util/stack';

const Signature = ({ value, onChange }: any) => {
    const sigCanvasRef = useRef<SignatureCanvas>(null);
    const [signature, setSignature] = useState<string | number | boolean>(value);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { t } = useTranslation();

    const save = () => {
        const data = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');

        setSignature(data);
        onChange(data);

        sigCanvasRef.current.clear();
    };

    return (
        <Openable
            value={signature ? <img alt="user signature" src={signature as string} width="115px" /> : null}
            label={t('Please Sign')}
            icon={cilChevronTop}
            forceClose={false}
        >
            {/** Render the canvas from react-signature */}
            <StyleWrapper backgroundColor="white">
                <SignatureCanvas ref={sigCanvasRef} penColor="black" canvasProps={{ width: 700, height: '200px', className: 'sigCanvas' }} />
            </StyleWrapper>

            <Stack direction="row">
                <CButton
                    onClick={() => canvasRef.current?.getContext('2d')?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)}
                    color="warning"
                >
                    {t('Clear')}
                </CButton>
                <CButton onClick={() => save()} color="success">
                    {t('Save')}
                </CButton>
            </Stack>
        </Openable>
    );
};

export default Signature;

