export function isTimeFormatPattern(pattern: string): boolean {
    if (!pattern.trim()) return false;
    // This regex pattern identifies common time format components (H, M, S),
    // and allows for any separator.
    const timeFormatRegex = /^([H]{1,2}|[M]{1,2}|[S]{1,2}|[^a-zA-Z0-9])+$/;

    // Count the number of separators
    const separatorCount = pattern.split('').filter((char) => !/[HMS]/.test(char)).length;

    // Check for adjacent separators
    const adjacentSeparators = /[^a-zA-Z0-9]{2,}/.test(pattern);

    return timeFormatRegex.test(pattern) && separatorCount <= 2 && !adjacentSeparators;
}

// Edge case tests
const tests = [
    '',
    ':',
    ',,',
    '::',
    'H',
    'M',
    'S',
    'HHHH',
    'MMMM',
    'SSSS',
    '::',
    '--',
    '..',
    ':-',
    '-:',
    'H:M:S',
    'H-M-S',
    'H.M.S',
    'H:-M',
    'H-.-S',
    'M:H',
    'S:M:H',
    'S-M-H',
    'H:M:S:Z',
    'H-M-S-F',
    'H:M:S:F',
    'H@M',
    'H!M!S',
    'H:M@S',
    'H!M-S',
    'HH:MM',
    'HH:SS',
    'MM:SS',
    'SS:HH',
    'SS:HH:MM',
];

if (require.main === module) {
    // Run this file directly to test edge cases
    tests.forEach((pattern) => {
        console.log(`Pattern: ${pattern}; Is Time Format: ${isTimeFormatPattern(pattern)}`);
    });
}
/**
 * Output:
 *  [LOG]: "Pattern: ; Is Time Format: false" 
    [LOG]: "Pattern: :; Is Time Format: true" 
    [LOG]: "Pattern: ,,; Is Time Format: false" 
    [LOG]: "Pattern: ::; Is Time Format: false" 
    [LOG]: "Pattern: H; Is Time Format: true" 
    [LOG]: "Pattern: M; Is Time Format: true" 
    [LOG]: "Pattern: S; Is Time Format: true" 
    [LOG]: "Pattern: HHHH; Is Time Format: true" 
    [LOG]: "Pattern: MMMM; Is Time Format: true" 
    [LOG]: "Pattern: SSSS; Is Time Format: true" 
    [LOG]: "Pattern: ::; Is Time Format: false" 
    [LOG]: "Pattern: --; Is Time Format: false" 
    [LOG]: "Pattern: ..; Is Time Format: false" 
    [LOG]: "Pattern: :-; Is Time Format: false" 
    [LOG]: "Pattern: -:; Is Time Format: false" 
    [LOG]: "Pattern: H:M:S; Is Time Format: true" 
    [LOG]: "Pattern: H-M-S; Is Time Format: true" 
    [LOG]: "Pattern: H.M.S; Is Time Format: true" 
    [LOG]: "Pattern: H:-M; Is Time Format: false" 
    [LOG]: "Pattern: H-.-S; Is Time Format: false" 
    [LOG]: "Pattern: M:H; Is Time Format: true" 
    [LOG]: "Pattern: S:M:H; Is Time Format: true" 
    [LOG]: "Pattern: S-M-H; Is Time Format: true" 
    [LOG]: "Pattern: H:M:S:Z; Is Time Format: false" 
    [LOG]: "Pattern: H-M-S-F; Is Time Format: false" 
    [LOG]: "Pattern: H:M:S:F; Is Time Format: false" 
    [LOG]: "Pattern: H@M; Is Time Format: true" 
    [LOG]: "Pattern: H!M!S; Is Time Format: true" 
    [LOG]: "Pattern: H:M@S; Is Time Format: true" 
    [LOG]: "Pattern: H!M-S; Is Time Format: true" 
    [LOG]: "Pattern: HH:MM; Is Time Format: true" 
    [LOG]: "Pattern: HH:SS; Is Time Format: true" 
    [LOG]: "Pattern: MM:SS; Is Time Format: true" 
    [LOG]: "Pattern: SS:HH; Is Time Format: true" 
    [LOG]: "Pattern: SS:HH:MM; Is Time Format: true" 
 */
