import React, { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
    const autoCompleteDropdownRef = useRef<React.RefObject<HTMLDivElement>>(null);

    useEffect(() => {
        autoCompleteDropdownRef.current = document.querySelector('.pac-container');

        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current?.contains(event.target as Node) && autoCompleteDropdownRef.current && !autoCompleteDropdownRef.current?.contains(event.target as Node)) {
                callback();
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function ClickOutsideDetecter(props: { callback: () => void; children: string | number | boolean | React.ReactNode | Iterable<React.ReactNode> | React.ReactPortal | null | undefined }) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.callback);

    return <div ref={wrapperRef}>{props.children}</div>;
}
