import { PageName } from '../types';

export const PASSWORD_LEN = 8;
// Represents columns that should never be changed.

export type StaticRoutes = {
    login: string;
    logout: string;
    dashboard: PageName;
    page404: string;
    page500: string;
    systemInitialization: string;
};

export const staticRoutes: StaticRoutes = {
    // In case we wanted to change the url of these common routes
    login: '/login',
    logout: '/logout',
    dashboard: '/dashboard',
    page404: '/404',
    page500: '/500',
    systemInitialization: '/system/initialization',
};
// Define specific values that should be highlighted with a badge.
// TODO: Should we store this in the database?
export const selectionOptionsBadges: Record<string, 'success' | 'warning' | 'danger' | 'info'> = {
    Pending: 'warning',
    Approved: 'success',
    Completed: 'success',
    Rejected: 'danger',
    Cancelled: 'danger',
    'In Progress': 'info',
    'In Review': 'info',
    High: 'danger',
    Medium: 'warning',
    Low: 'success',
    Active: 'success',
    Inactive: 'danger',
    low: 'success',
};
export const WS_URL = ((process.env.REACT_APP_WEBSOCKET_HOST as string) + process.env.REACT_APP_WEBSOCKET_PORT + process.env.REACT_APP_WEBSOCKET_URL) as string;
