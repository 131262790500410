import { staticRoutes, WS_URL } from '../../configuration/constants';
import { Operation } from '../../types';
import { formatMessage } from './formatMessage';

export type MessageBody = {
  [key: string]: string;
};


export interface Message {
  id: string;
  url: string;
  body?: MessageBody;
  operation: Operation;
  token?: string;
}

class WebSocketSingleton {
  private static instance: WebSocketSingleton;
  private socket: WebSocket | null = null;
  private messageQueue = <Message[]>([]);
  private reconnect = 3000;
  private promisesMap = {}; // To store the resolve functions of promises

  private constructor() {
    this.connect();
  }

  public static getInstance(): WebSocketSingleton {
    if (!WebSocketSingleton.instance) {
      WebSocketSingleton.instance = new WebSocketSingleton();
    }
    return WebSocketSingleton.instance;
  }

  private connect() {
    const resendFromQueue = () => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.messageQueue.forEach(msg => this.socket?.send(JSON.stringify(msg)));
        this.messageQueue = [];
      } else {
        console.error('WebSocket still not opened.');
        setTimeout(() => {
          this.connect();
        }, this.reconnect);
      }
    };

    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.socket = new WebSocket(WS_URL);

      this.socket.onopen = () => {
        console.log('WebSocket connection established');
        resendFromQueue();
      };

      this.socket.onmessage = (event) => {
        console.log('WebSocket message received:', event.data);
        const message = JSON.parse(event.data);
        if (message && message.id && this.promisesMap[message.id]) {
          const promise = this.promisesMap[message.id];
          switch (message.status) {
            case 'ok':
              promise.resolve(message.body);
              break;
            case 'error':
              promise.reject();
              break;
            case 'redirect':
              if (window.location.pathname !== staticRoutes.login && message.body?.path) {
                window.location.href = message.body.path;
              }
              break;
          }
        }
      };

      this.socket.onclose = (event) => {
        console.log('WebSocket disconnected:', event.code, event.reason);
        // Automatically try to reconnect if the disconnection was not intentional
        if (event.code !== 1000) { // 1000 denotes a normal closure
          setTimeout(() => this.connect(), this.reconnect); // retry connection after 5 seconds
        }
      };

      this.socket.onerror = (error) => {
        console.log('WebSocket error:', error);
      };
    } else {
      resendFromQueue();
    }
  }

  public send(url: string, operation: Operation, body?: MessageBody, id = ''): Promise<Message> {
      if (!url || url === '') {
        return Promise.reject();
      }
      if (!id) id = url;

      let future;
      const promise = new Promise<Message>((resolve, reject) => {
        future = {resolve, reject};
      });
      try {
        const formattedMessage = formatMessage({ id, url, operation, body });
        if (formattedMessage === undefined) {
          console.error('Requested message to send is corrupted!');
          return Promise.reject();
        }
        this.promisesMap[formattedMessage.id] = future; // Store the resolve function using message ID
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          const message = JSON.stringify(formattedMessage);
          console.info('Requested message to send is: ' + message);
          this.socket.send(message);
        } else {
          this.messageQueue.push(formattedMessage);
          this.connect();
        }
      } catch (error) {
        console.log('error send message', error);
        return Promise.reject(error);
      }
    return promise;
  }

  public close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

export default WebSocketSingleton;
