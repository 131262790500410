import { Style } from '../../../types';

const style: Style = {
    flexGroups: [],
    individualStyles: {
        self: {
            backgroundImage: 'linear-gradient(to bottom, #f0f0f0, #b3e5fc)',
            padding: '10px 25px',
            '& div.form-control div:not(div.invalid-feedback)': {
                color: '#8aacb8',
            },
            '& div.form-control': {},
            '&  input': {
                borderRadius: '8px',
                padding: '0px 10px',
            },
            '& svg *': {
                width: '51px',
                height: '51px',
                background: 'transparent',
            },
            '& label *': {
                width: '51px',
                height: '51px',
                background: 'transparent',
            },
        },
        tag1: {
            fontWeight: 600,
            borderRadius: '8px',
            background: 'white',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
        },
        tag2: {
            fontWeight: 600,
            borderRadius: '8px',
            background: 'white',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
        },
        tag3: {
            fontWeight: 600,
            borderRadius: '8px',
            background: 'white',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
        },
        'barcode-img': {
            width: '45% !important',
        },
        'car-ocr': {
            '&': {
                paddingBottom: '40px',
                borderBottom: '3px solid white',
            },
            '& #barcode': {
                background: 'white',
                borderRadius: '8px',
                boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
            },
            '& #barcode *:not(div.invalid-feedback)': {
                textAlign: 'center',
                borderRadius: '8px',
            },
            '& #barcode-img *:not(div.invalid-feedback)': {
                background: 'transparent',
            },
        },
        form__email: {
            '&': {
                borderTop: '3px solid white',
                borderBottom: '3px solid white',
                marginTop: '30px',
                marginBottom: '40px',
            },
            '& .form-control': {
                background: 'transparent',
            },

            '& label': {
                background: '#b5dbe8',
                color: '#8aacb8',
                padding: '5px 10px',
            },
            '& input#form-inputEMail': {
                paddingLeft: '50px',
            },
        },
        form__location: {
            '& #location-manual *': {
                marginLeft: '5px',
                color: 'black',
            },
            '& #location-auto': {
                background: 'transparent',
            },
            '& #location-auto *': {
                color: 'black',
                background: 'transparent !important',
                boxShadow: 'none !important',
            },
            '& #location-auto svg': {
                transform: 'scale(2)',
            },
        },
        submit: {
            marginTop: '-50px',
            backgroundColor: {
                $condition: {
                    select: 'field.submit',
                    compare: 'ready',
                    iftrue: 'blue',
                    else: 'gray',
                },
            },
            color: 'white',
            fontWeight: 'bold',
        },
    },
};

export default style;
