// the WidgetInterface, against which all incoming objects are tested:
// export type Operation = 'GET' | 'CREATE' | 'UPDATE' | 'DELETE';

// export type PageName = '/dashboard' | '/tasks' | '/inventory' | '/employees' | '/clients' | '/gallery' | '/map' | '/logout';

// export type Permission = 'r' | 'w' | 'd';

// export type WidgetType = 'submit' | 'location' | 'image' | 'multi-page' | 'table' | 'map' | 'calendar' | 'gallery' | 'video' | 'audio' | 'diagram' | 'statistics' | 'form';

// export type StatisticsSubtype = 'sum' | 'avg' | 'min' | 'max';
// export type FormSubtype = Operation;
// type DynamicCSSProperties = {
//     [Property in keyof SxProps]: SxProps[Property] | CustomStyleCondition<SxProps[Property]>;
// };
// export type Style = {
//     flexGroups: Array<{
//         elements: string[];
//         flexProperties: React.CSSProperties;
//     }>;
//     individualStyles: {
//         [key: string]: DynamicCSSProperties;
//     };
// };
// export type FieldPermission = 'r' | 'w' | 'd';

// export type FieldProperties = 'd' | 'e' | 'r' | 's' | 'v';

// export type FieldSize = `h${number}` | `l${number}` | `b${number},${number}`;

// export const fieldType = [
//     'autocomplete',
//     'text',
//     'textarea',
//     'regex',
//     'selection',
//     'date',
//     'date-range',
//     'date',
//     'number',
//     'email',
//     'password',
//     'location',
//     'link',
//     'file',
//     'radio',
//     'signature',
//     'map',
//     'selectable-list',
//     'button',
//     'repairCostField'
// ] as const;

// export type FieldType = (typeof fieldType)[number];

// export interface FieldInterface {
//     _type: FieldType;
//     _subtype?: 'single' | 'multiple' | 'image';
//     _value?: string; // Represents a field's default value
//     _key: string;
//     _label?: string;
//     _required?: boolean;
//     _readonly?: boolean;
//     _permission?: FieldPermission[];
//     _size?: FieldSize;
//     _icon?: Source;
//     _source?: Source;
//     _prerequisite?: Source;
// }

// export interface Source {
//     type: 'literal' | 'field' | 'url';
//     value: string;
//     defaultValue?: string;
// }

// export type WidgetInterface = {
//     _type: WidgetType;
//     style?: Style;
//     _subtype?: FormSubtype | StatisticsSubtype | 'multi' | 'ocr-closeup';
//     _id: string;
//     _label?: string;
//     _permission?: Permission[];
//     _icon?: Source;
//     _columnWeight?: number;
//     _position?: number;
//     _url?: string;
//     _content?: FieldInterface[];
//     _source?: Source;
// };

// The logic to test whether the incoming object is a valid WidgetInterface:
import { WidgetInterface, WidgetType } from '../types';

export const isValidWidgetInterface = (widget: any): widget is WidgetInterface => {
  if (typeof widget !== 'object' || widget === null) {
    return false;
  }

  const validTypes: WidgetType[] = [
    'submit',
    'location',
    'TableWidget',
    'image',
    'multi-page',
    'table',
    'map',
    'calendar',
    'gallery',
    'video',
    'audio',
    'diagram',
    'statistics',
    'form',
  ];

  if (!validTypes.includes(widget._type)) {
    return false;
  }

  const validStatisticsSubtypes: StatisticsSubtype[] = ['sum', 'avg', 'min', 'max'];
  const validFormSubtypes: FormSubtype[] = ['GET', 'CREATE', 'UPDATE', 'DELETE'];

  if (widget._type === 'statistics' && widget._subtype && !validStatisticsSubtypes.includes(widget._subtype as StatisticsSubtype)) {
    return false;
  }

  if (widget._type === 'form' && widget._subtype && !validFormSubtypes.includes(widget._subtype as FormSubtype)) {
    return false;
  }

  if (typeof widget._id !== 'string') {
    return false;
  }

  if (widget._label && typeof widget._label !== 'string') {
    return false;
  }

  const validPermissions: Permission[] = ['r', 'w', 'd'];

  if (widget._permission) {
    if (!Array.isArray(widget._permission)) {
      return false;
    }

    for (const permission of widget._permission) {
      if (!validPermissions.includes(permission)) {
        return false;
      }
    }
  }

  if (widget._icon) {
    if (!isValidSource(widget._icon)) {
      return false;
    }
  }

  if (widget._columnWeight && typeof widget._columnWeight !== 'number') {
    return false;
  }

  if (widget._position && typeof widget._position !== 'number') {
    return false;
  }

  if (widget._url && typeof widget._url !== 'string') {
    return false;
  }

  if (widget._content) {
    if (!Array.isArray(widget._content)) {
      return false;
    }

    for (const field of widget._content) {
      if (!isValidFieldInterface(field)) {
        return false;
      }
    }
  }

  if (widget._source) {
    if (!isValidSource(widget._source)) {
      return false;
    }
  }

  return true;
};

const isValidSource = (source: any): source is Source => {
  if (typeof source !== 'object' || source === null) {
    return false;
  }

  const validSourceTypes = ['literal', 'field', 'url'];

  if (!validSourceTypes.includes(source.type)) {
    return false;
  }

  if (typeof source.value !== 'string') {
    return false;
  }

  if (source.defaultValue && typeof source.defaultValue !== 'string') {
    return false;
  }

  return true;
};

const isValidFieldInterface = (field: any): field is FieldInterface => {
  if (typeof field !== 'object' || field === null) {
    return false;
  }

  const validFieldTypes: FieldType[] = [
    'autocomplete',
    'text',
    'textarea',
    'regex',
    'selection',
    'date',
    'date-range',
    'number',
    'email',
    'password',
    'location',
    'link',
    'file',
    'radio',
    'signature',
    'map',
    'selectable-list',
    'button',
    'repairCostField',
  ];

  if (!validFieldTypes.includes(field._type)) {
    return false;
  }

  const validFieldSubtypes = ['single', 'multiple', 'image'];

  if (field._subtype && !validFieldSubtypes.includes(field._subtype)) {
    return false;
  }

  if (typeof field._key !== 'string') {
    return false;
  }

  if (field._label && typeof field._label !== 'string') {
    return false;
  }

  if (field._required && typeof field._required !== 'boolean') {
    return false;
  }

  if (field._readonly && typeof field._readonly !== 'boolean') {
    return false;
  }

  const validFieldPermissions: FieldPermission[] = ['r', 'w', 'd'];

  if (field._permission) {
    if (!Array.isArray(field._permission)) {
      return false;
    }

    for (const permission of field._permission) {
      if (!validFieldPermissions.includes(permission)) {
        return false;
      }
    }
  }

  if (field._size) {
    if (typeof field._size !== 'string') {
      return false;
    }

    if (!field._size.includes(',') && !field._size.startsWith('h') && !field._size.startsWith('l')) {
      return false;
    }
  }

  if (field._icon) {
    if (!isValidSource(field._icon)) {
      return false;
    }
  }

  if (field._source) {
    if (!isValidSource(field._source)) {
      return false;
    }
  }

  if (field._prerequisite) {
    if (!isValidSource(field._prerequisite)) {
      return false;
    }
  }

  return true;
};
