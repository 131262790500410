import { CFormInput, CInputGroupText } from '@coreui/react-pro';

import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface AddressSearchProps {
    onPlaceChanged: () => void;
}

const AddressSearch: React.FC<AddressSearchProps> = forwardRef((props, ref) => {
    const autoCompleteRef = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const registerEventListener = () => {
        if (autoCompleteRef.current) {
            const autocomplete = new google.maps.places.Autocomplete(autoCompleteRef.current);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();

                if (place) {
                    // Assuming setUserLocation is passed as props or available via context
                    props.onPlaceChanged();
                }
            });
        }
    };

    useEffect(() => {
        registerEventListener();
    }, []); // The empty dependency array ensures this useEffect runs once, similar to componentDidMount

    useImperativeHandle(ref, () => ({
        registerEventListener: registerEventListener,
    }));

    return (
        <CInputGroupText
            style={{
                padding: 0,
                width: 'fit-content',
                backgroundColor: 'transparent',
            }}
        >
            <CFormInput ref={autoCompleteRef} type="text" placeholder={t('Search location')} />
        </CInputGroupText>
    );
});

AddressSearch.displayName = 'AddressSearch';

export default AddressSearch;
