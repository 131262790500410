import React from 'react';
import Icon from '../mui-icon/Icon';
import useAppSelect from '../../services/redux/useAppSelect';
import { CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useTranslation } from 'react-i18next';
import { getDefaultValues, setAppProperties } from './SettingsPanel';
import useAppDispatch from '../../services/redux/useAppDispatch';

interface RevertButtonProps {
    name: string;
    onClick: () => void;
    reset: (data: object) => void;
}

const RevertButton: React.FC<RevertButtonProps> = ({ name, onClick, reset }) => {
    const theme = useAppSelect('theme');
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const handleResetAll = () => {
        if (window.confirm(t('Are you sure you want to reset all settings?'))) {
            const resetTo = getDefaultValues(null);
            setAppProperties(resetTo, dispatch, i18n);
            reset(resetTo);
        }
    };

    return (
        <CDropdown className="settings-panel-dropdown">
            <CDropdownToggle>
                <div
                    style={{
                        alignSelf: 'center',
                        cursor: 'pointer',
                        color: theme === 'light-theme' ? 'black' : 'white',
                        background: 'transparent !important',
                    }}
                >
                    <Icon name="History"></Icon>
                </div>
            </CDropdownToggle>

            <CDropdownMenu>
                <CDropdownItem onClick={onClick}>{t('Reset ' + name)}</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem onClick={handleResetAll}>{t('Reset All')}</CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default RevertButton;
 
