import { cilCloudUpload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCloseButton } from '@coreui/react-pro';
import { Stack } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSource } from '../../../utils';
import Icon from '../../mui-icon/Icon';
import StyleWrapper from '../../style-wrapper';
import Label from '../Label';
import { FieldComponentProps } from '../Field';

const fileTypes = {
    image: '.png, .jpg, .gif, .bmp, .svg, .ico, .jpeg',
    video: '.mp4, .mov, .avi, .wmv, .vob',
    document: '.pdf, .doc, .txt, .xls, .ppt, .odp',
    audio: '.mp3, .m4a, .wav',
};

// Adjusted styles for better alignment
const fileInputStyle = {
    display: 'none',
};

const labelStyle = {
    marginTop: '0',
    backgroundColor: 'var(--cui-table-bg)',
    cursor: 'pointer',
    border: '1px solid rgba(var(--bt-disabled-rgb), 0.2)',
    borderRadius: '4px',
    minHeight: '35px',
    padding: '0.75rem',
};

const selectedFileNameStyle = {
    marginTop: '-8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50px',
};

const File = (props: PropsWithChildren<FieldComponentProps>) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { t } = useTranslation();
    const { value: iconSource } = useSource(props.config._icon);

    const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const expectedFileType = props.config._subtype ?? 'image';
        const expectedFileSubtype = fileTypes[expectedFileType];

        const file = e.target.files?.[0];
        if (!file) return;

        const foundFile = file && file.type?.split('/');
        if (!foundFile) {
            console.warn('Unexpected file type:', file.type);

            return;
        }

        const filePrefix = foundFile[0];
        const fileSuffix = foundFile[1];

        // Make sure the file type matches the expected type
        // Check if the file's MIME type matches the expected file type
        if (filePrefix !== expectedFileType && !fileSuffix?.includes(expectedFileSubtype.replaceAll('.', ''))) {
            console.warn('Unexpected file type:', file.type);

            return;
        }

        try {
            const base64 = await toBase64(file);
            setSelectedFile(file);

            if (props.onChange) {
                props.onChange({ base64, name: file.name });
            }
        } catch (error) {
            console.error('File conversion error:', error);
        }
    };

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const clickInput = React.useCallback(() => {
        fileInputRef.current?.click();
    }, []);

    return (
        <Stack sx={{ background: 'transparent' }} direction="column" spacing={2} marginTop="auto" marginBottom="auto">
            {' '}
            {/* Added spacing for consistent gaps */}
            <input ref={fileInputRef} {...(props.config._subtype && { accept: fileTypes[props.config.__subtype] })} type="file" id={props.id} onChange={handleFileChange} style={fileInputStyle} />
            {props.config._label && (
                <StyleWrapper display="flex" alignItems="center" justifyContent="space-between">
                    <Label required={props.config._required}>{t(props.config._label)}</Label>

                    <div>
                        <label htmlFor={props.id} style={labelStyle}>
                            {t(props.config._label ?? 'upload')} <CIcon size="sm" icon={cilCloudUpload}></CIcon>
                        </label>
                    </div>
                </StyleWrapper>
            )}
            <Stack direction="row-reverse" spacing={2} style={{ marginTop: 'auto', marginBottom: 'auto', background: 'transparent' }} justifyContent="space-between">
                {props.config._icon && (
                    <label onClick={clickInput} htmlFor={props.id} style={{ cursor: 'pointer' }}>
                        <Icon color="black" name={iconSource ?? 'QrCodeScannerOutlined'} />
                    </label>
                )}

                {props.config._label && (
                    <StyleWrapper display="flex" alignItems="center" gap="0.5rem" marginTop="auto" marginBottom="auto">
                        {selectedFile?.name && (
                            <CCloseButton
                                white
                                onClick={() => {
                                    setSelectedFile(null);
                                    if (props.onChange) {
                                        props.onChange(undefined);
                                    }
                                }}
                            />
                        )}
                        <label style={selectedFileNameStyle}>{selectedFile?.name ?? props?.value?.name}</label>
                    </StyleWrapper>
                )}
            </Stack>
        </Stack>
    );
};

export default File;
