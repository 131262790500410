import React, { PropsWithChildren } from 'react';
import useAppSelect from '../../services/redux/useAppSelect';
import { useMediaQuery } from '@mui/material';

const ContentNavbarAdjuster = (props: PropsWithChildren) => {
    const sidebarUnfoldable = useAppSelect('sidebarUnfoldable');
    const isRtl = useAppSelect('rtl');

    const paddingAmount = sidebarUnfoldable ? '4rem' : '12rem';

    const paddingLeft = isRtl ? '0px' : paddingAmount;
    const paddingRight = isRtl ? paddingAmount : '0px';

    const isMobile = useMediaQuery('(max-width:768px)');

    // Don't apply paddings for mobile
    if (isMobile) {
        return <>{props.children}</>;
    }

    return <div style={{ paddingLeft, paddingRight, position: 'sticky', width: '100%' }}>{props.children}</div>;
};

export default ContentNavbarAdjuster;
 
