import { useMediaQuery } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface BaseProps {
    WrappedComponent?: React.FC<PropsWithChildren<{ style: React.CSSProperties }>>;
    children: JSX.Element | JSX.Element[];
}

interface StyleWrapperProps extends BaseProps {
    [key: string]: string | [string?, string?, string?, string?] | unknown;
}

const StyleWrapper = (props: PropsWithChildren<StyleWrapperProps>) => {
    const { children, WrappedComponent, ...styles } = props;
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
    const isLaptop = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
    const isDesktop = useMediaQuery('(min-width: 1281px) and (max-width: 1920px)');

    const appliedStyles = {};

    Object.entries(styles).forEach(([key, value]) => {
        const property = key as keyof React.CSSProperties;

        if (typeof value === 'string') {
            appliedStyles[property] = value;
        } else if (Array.isArray(value)) {
            if (isMobile && value[0]) {
                appliedStyles[property] = value[0];
            } else if (isTablet && value[1]) {
                appliedStyles[property] = value[1];
            } else if (isLaptop && value[2]) {
                appliedStyles[property] = value[2];
            } else if (isDesktop && value[3]) {
                appliedStyles[property] = value[3];
            }
        }
    });

    if (WrappedComponent) {
        return <WrappedComponent style={appliedStyles}>{children}</WrappedComponent>;
    } else {
        return <div style={appliedStyles}> {children}</div>;
    }
};

export default StyleWrapper;
