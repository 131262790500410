import { CBadge, CCard, CSmartTable } from '@coreui/react-pro';
import { Divider, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import Loader from '../loader/Loader';
import { WidgetProps } from '../page/Page';
import BaseTableHeader from './Header';
import TableModal from './Modal';
import { FieldInterface } from '../../types';
import useWebSocket from '../../services/websocket/useWebSocket';
import Icon from '../mui-icon/Icon';

interface Item {
    avatar: string;
    registered: string;
    status: string;
    username: string;
    id: string;
}

const csmartTableBaseProps = {
    id: 'base-table',
    activePage: 1,
    cleaner: true,
    clickableRows: true,
    columnFilter: true,
    columnSorter: {
        resetable: true,
        external: true,
    },
    footer: false,
    itemsPerPageSelect: true,
    itemsPerPage: 5,
    pagination: true,
    selectable: true,
    tableFilter: true,
    tableProps: {
        className: '',
        responsive: true,
        striped: true,
        hover: true,
    },
    tableBodyProps: {
        className: 'align-middle',
    },
};

const fillMissingColumns = (rows: Record<string, unknown>[], columns: FieldInterface[]) => {
    return rows.map(row => {
        const newRow = { ...row };
        columns.forEach(column => {
            if (!newRow[column._key]) {
                newRow[column._key] = '';
            }
        });
        return newRow;
    });
};

const BaseTable = ({ widget }: WidgetProps) => {
    const dispatch = useAppDispatch();
    const rootPath = widget._id;

    const runtime = useAppSelect('runtime');

    const rows = (runtime[rootPath]?.rows as Record<string, unknown>[]) ?? [];
    console.log('rows', rows);
    const columns = (runtime[rootPath]?.columns as FieldInterface[]) ?? [];

    const { sendMessage } = useWebSocket();
    const { t } = useTranslation();

    const loading = false;

    const excludeColumns = ['password', 'permission', 'settings'];
    const status = ['started', 'completed', 'paused', 'Aborted'];
    const roles = ['admin', 'user'];

    const filteredColumns = columns
        .filter(column => !excludeColumns.includes(column._key) && Boolean(column._key))
        .map(column => ({ type: column._type, key: column._key ?? column._label, label: t(column._label), name: column._key, id: column._key }));

    console.log('filteredColumns', filteredColumns);

    filteredColumns.push({
        key: 'action',
        label: '',
        type: 'actions',
        filter: false,
        sorter: false,
        _cellProps: {
            className: 'sticky-right',
            style: { right: 0, position: 'sticky', backgroundColor: '#fff', zIndex: 2 },
        },
        _props: { scope: 'col' },
    });

    const cellStyle = {
        padding: '0.5rem',
        boxShadow: 'inset 0 0 0 9999px var(--cui-table-bg-state, var(--cui-table-bg-type, var(--cui-table-accent-bg)))',
        minHeight: '61px',
    };

    const scopedColumns = filteredColumns.reduce((acc, column) => {
        switch (column.type) {
            case 'selection':
                acc[column.key] = (row: Item) => (
                    <td style={cellStyle}>
                        <CBadge style={{ alignSelf: 'center', justifySelf: 'center' }} color={row[column.key] === '0' ? 'primary' : 'info'}>
                            {t(column.key === 'role' ? roles[row[column.key]] : status[row[column.key]])}
                        </CBadge>
                    </td>
                );
                break;

            case 'email':
                acc[column.key] = (row: Item) => (
                    <td style={cellStyle}>
                        <a style={{ padding: '0.5rem' }} href={`mailto:${row[column.key]}`}>
                            {row[column.key]}
                        </a>
                    </td>
                );

                break;
        }

        return acc;
    }, {});

    scopedColumns['action'] = (row: Item) => (
        <td style={{ backgroundColor: 'var(--cui-card-bg)', position: 'sticky', right: '-0px', top: '0px' }}>
            <div>
                <IconButton
                    style={{ backgroundColor: 'rgba(0,0,0,0.15)' }}
                    disableRipple
                    disableTouchRipple
                    onClick={() => {
                        dispatch(updateRuntimeData(`${rootPath}.mode`, 'UPDATE'));
                        dispatch(updateRuntimeData(`${rootPath}.editRow`, row));
                    }}
                >
                    <Icon name="EditOutlined" style={{ color: 'white' }} />
                </IconButton>
            </div>
        </td>
    );

    useEffect(() => {
        // Initiate the global storage for the table based on it's id
        const storagePaths = [`${rootPath}.sortedRows`, `${rootPath}.modal`, `${rootPath}.loading`];
        storagePaths.forEach(path => dispatch(updateRuntimeData(path, {})));

        dispatch(updateRuntimeData(`${rootPath}.rows`, []));
        dispatch(updateRuntimeData(`${rootPath}.columns`, widget?._content ?? []));
        dispatch(updateRuntimeData(`${rootPath}.mode`, 'view')); // default table mode is viewing
        dispatch(updateRuntimeData(`${rootPath}.postUrl`, widget._url));
    }, []);

    useEffect(() => {
        sendMessage(widget._url ?? '', 'GET')
          .then(response => {
            dispatch(updateRuntimeData(`${rootPath}.rows`, response));
        });
    }, [widget._url]);

    return (
        <div style={{ overflowY: 'auto' }}>
            {loading && <Loader type="linear" />}
            <Divider className="my-4" />
            <BaseTableHeader title={t(widget._label)} id={rootPath} />

            <CCard className="px-4 py-2" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                <TableModal id={rootPath} />

                {columns.length > 0 ? (
                    <CSmartTable
                        {...csmartTableBaseProps}
                        loading={loading}
                        columns={filteredColumns}
                        items={fillMissingColumns(rows, columns)}
                        noItemsLabel={t('No items')}
                        tableFilterLabel={t('Search')}
                        itemsPerPageLabel={t('Items per page')}
                        tableFilterPlaceholder={t('Type here') + '...'}
                        onSorterChange={sorter => {
                            if (!sorter || !rows.length) return;
                            const { state, column } = sorter;
                            const sortedRows = rows.slice().sort((a, b) => {
                                const valueA = String(a[column ?? ''])?.toLowerCase();
                                const valueB = String(b[column ?? ''])?.toLowerCase();
                                return state === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                            });
                            dispatch(updateRuntimeData(`${rootPath}.sortedRows`, sortedRows));
                        }}
                        scopedColumns={scopedColumns}
                    />
                ) : (
                    <div>No columns defined</div>
                )}
            </CCard>
        </div>
    );
};

export default React.memo(BaseTable);
