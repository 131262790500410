import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { staticRoutes } from '../configuration/constants';
import { setSidebarHover, setSidebarShow, setSidebarUnfoldable } from '../services/redux/globalStateManager';
import useAppDispatch from '../services/redux/useAppDispatch';
import useAppSelect from '../services/redux/useAppSelect';
import { AppSidebarNav } from './AppSidebarNav';
import RtlAdjustHorizontal from './RtlAdjustHorizontal';
import { TextReveal } from './foldable/Text';

const AppSidebar = () => {
    const dispatch = useAppDispatch();
    const unfoldable = useAppSelect('sidebarUnfoldable');
    const sidebarShow = useAppSelect('sidebarShow');
    const { t } = useTranslation();
    const isRtl = useAppSelect('rtl');
    const navigate = useNavigate();

    React.useEffect(() => {
        const body = document.querySelector('div.body');
        if (body) {
            if (!unfoldable) {
                // Remove wide and add narrow
                body.className = body.className.replace('adjust-to-sidebar-narrow', '');
                body.className = body.className = body.className + ' adjust-to-sidebar-wide';
            } else {
                // Remove narrow and add wide
                body.className = body.className.replace('adjust-to-sidebar-wide', '');
                body.className = body.className = body.className + ' adjust-to-sidebar-narrow';
            }
        }
    }, [unfoldable]);

    return (
        <RtlAdjustHorizontal>
            <CSidebar
                position="fixed"
                unfoldable={unfoldable}
                placement={isRtl ? 'end' : 'start'}
                visible={sidebarShow}
                onMouseEnter={() => {
                    dispatch(setSidebarHover(true));
                }}
                onMouseLeave={() => {
                    dispatch(setSidebarHover(false));
                }}
                onVisibleChange={visible => {
                    dispatch(setSidebarShow(visible));
                }}
            >
                <CSidebarBrand
                    className="d-none d-md-flex"
                    style={{
                        justifyContent: 'flex-start',
                        paddingLeft: '0.75rem',
                        paddingRight: '0.7rem',
                        cursor: 'pointer',
                        marginBottom: '4.25rem',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={() => navigate(staticRoutes.dashboard)}
                >
                    <img
                        alt="Betec Dashboard"
                        src="/betec.png"
                        width={45}
                        style={{
                            marginTop: '0.75rem',
                            marginRight: '-0.125rem',
                            marginLeft: '-0.125rem',
                        }}
                    />
                    <div style={{ marginTop: '0.25rem' }}>
                        <TextReveal>{t('Betec Dashboard')}</TextReveal>
                    </div>
                </CSidebarBrand>
                <CSidebarNav>
                    <SimpleBar>
                        <AppSidebarNav />
                    </SimpleBar>
                </CSidebarNav>
                <CSidebarToggler
                    className="d-lg-flex"
                    onClick={() => {
                        dispatch(setSidebarUnfoldable(!unfoldable));
                    }}
                />
            </CSidebar>
        </RtlAdjustHorizontal>
    );
};

export default React.memo(AppSidebar);
