import { CNavLink } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RouteName, changeTheme } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useWebSocket from '../../services/websocket/useWebSocket';
import Icon from '../mui-icon/Icon';
import StyleWrapper from '../style-wrapper';
import HeaderButton from './HeaderButton';
import LanguageDropdown from './LanguageDropdown';
import Notifications from './Notifications';

type NavRole = 'header' | 'dropdown';
interface NavButton {
    name: RouteName;
    role: NavRole;
    narrow: boolean;
}
const NavButton = (navProps: NavButton) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendMessage } = useWebSocket();

    const handleThemeChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.preventDefault();

        dispatch(changeTheme('toggle'));
    };

    const buttons: Record<RouteName, { icon: string; to: string; onClick?: (t: unknown) => void }> = {
        _user_profile: {
            icon: 'Portrait',
            to: '/profile',
        },
        _user_settings: {
            icon: 'Settings',
            to: '/settings',
        },
        logout: {
            icon: 'Logout',
            onClick: () => {
                if (window.confirm(t('Are you sure you want to logout?'))) {
                    sendMessage('/logout', 'GET');

                    localStorage.removeItem('user');
                    navigate('/login');
                }
            },
        },
        _themes: {
            onClick: handleThemeChange,
            icon: 'Brightness7',
        },
        _languages: {
            Component: LanguageDropdown,
            icon: 'Public',
        },
        _widgets: {
            Component: Notifications,
            narrow: false,
        },
        _pages: {
            narrow: false,
            icon: 'Email',
        },
    };

    const props = buttons[navProps.name] ?? {};

    if (props.narrow && navProps.narrow !== props.narrow) {
        return null;
    }

    return (
        <div className={'nav-item-' + navProps.role}>
            {navProps.role === 'dropdown' && (
                <CNavLink
                    component="div"
                    onClick={() => {
                        if (props.onClick) {
                            props.onClick();
                        }

                        if (props.to) {
                            navigate(props.to);
                        }
                    }}
                >
                    <StyleWrapper display="flex" gap="0.25rem">
                        <Icon name={buttons[navProps.name]?.icon} />
                        <span>{navProps.role === 'dropdown' && t(navProps.name)}</span>
                    </StyleWrapper>
                </CNavLink>
            )}
            {navProps.role === 'header' && <HeaderButton icon={buttons[navProps.name]?.icon} name={navProps.name} />}
        </div>
    );
};

export default NavButton;
