export const getUserLocation = (): Promise<Location> =>
    new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ lat: latitude, lng: longitude });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                    reject(error);
                }
            );
        } else {
            // Reject the promise if geolocation is not available
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });
