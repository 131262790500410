import { CHeaderDivider, CSidebarNav } from '@coreui/react-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import useAppSelect from '../services/redux/useAppSelect';
import useSendMessage from '../services/websocket/useWebSocket';
import { PageInterface } from '../types';
import { getLabel, getRoute } from '../utils';
import { TextReveal } from './foldable/Text';
import Icon from './mui-icon/Icon';
import StyleWrapper from './style-wrapper';
import { Box, Divider } from '@mui/material';

type BadgeProps = {
    color: string;
    text: string;
};

type NavItemProps = {
    name: string;
    icon?: string;
    badge?: BadgeProps;
    route?: string;
};

type NavGroupProps = {
    component: React.FC<unknown>;
    name: string;
    icon: string;
    to: string;
    items?: SidebarItem[];
    [key: string]: unknown;
};

type SidebarItem = NavItemProps | NavGroupProps;

interface AppSidebarNavProps {}

export const AppSidebarNav: React.FC<AppSidebarNavProps> = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const routes = useAppSelect('routes');
    const [pages, setPages] = useState<PageInterface[]>();
    const { sendMessage } = useSendMessage();

    useEffect(() => {
        if (routes?._pages) {
            sendMessage(routes._pages, 'GET')
              ?.then(response => {
                if (response) {
                  setPages(response);
                }
              });
        }
    }, [routes]);

    const navLink = (label: string, icon: string) => (
        <StyleWrapper display="flex" gap="0.75rem" alignItems="center">
            <Box sx={{ '& svg': { scale: '1.5', marginTop: -1, marginLeft: '0.2rem' } }}>
                <Icon name={icon ?? ''} />
            </Box>
            <TextReveal>{t(label.toLowerCase()) ?? label}</TextReveal>
        </StyleWrapper>
    );

    const navItem = (item: PageInterface, index: number) => {
        const url = getRoute(item);

        if (!url) {
            return null;
        }

        return (
            <NavLink
                key={index + getRoute(item)}
                className={'sidebar-nav-link-item' + (url === location.pathname ? ' active' : '')}
                style={{
                    color: 'var(--cui-white)',
                    padding: '1rem 1.25rem',
                    textDecoration: 'none',
                    fontSize: '1rem',
                    fontWeight: 100,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',

                    ...(url === location.pathname && {
                        color: 'var(--bt-primary-50) !important',
                        backgroundColor: 'var(--bt-primary-25)',
                        fontWeight: 700,
                    }),
                }}
                to={url}
            >
                {navLink(t(getLabel(item).toLowerCase()) ?? '', item?._icon ?? '')}
            </NavLink>
        );
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                color: 'var(--cui-body-color)',
            }}
        >
            <CSidebarNav>
                {Array.isArray(pages) && pages.map((item, index) => navItem(item, index))}

                {/* For development and debugging purposes only */}
                {window.location.hostname === 'localhost' && (
                    <div style={{ paddingLeft: '1rem' }}>
                        <Divider />
                        <h6 className="text-muted">Developer Only</h6>
                        {navItem({ _id: '404', _url: '404', _widgets: [], _name: t('404'), _icon: 'Error' }, -1)}
                        {navItem({ _id: '500', _url: '500', _widgets: [], _name: t('500'), _icon: 'NewReleases' }, -1)}
                        {navItem(
                            {
                              _id: 'systemInitialization',
                              _url: '/system/initialization',
                              _widgets: [],
                              _name: t('System Initialization'),
                                _icon: 'NewReleases'
                            },
                            -1
                        )}
                    </div>
                )}
            </CSidebarNav>

            <CHeaderDivider />
        </div>
    );
};
