import { PageInterface } from '../../../types';
import style from './demoPageStyle';

const page: PageInterface = {
    _id: 'demo',
    name: 'Demo',
    url: '/public/demo',
    sideMenu: false,
    footer: false,
    header: false,
    permission: [],
    style: style,
    widgets: [
        {
            _id: 'car-ocr',
            _type: 'form',
            _subtype: 'multi',
            _content: [
                {
                    key: 'barcode',
                    type: 'number',
                    properties: ['r', 'v', 'e'],
                    size: 'l1000',
                    source: {
                        type: 'field',
                        value: 'car-ocr.barcode-img',
                        transform: 'ocr',
                    },
                },
                {
                    key: 'barcode-img',
                    type: 'file',
                    properties: ['r', 'v', 'e'],
                    size: 'l1000',
                    icon: {
                        type: 'text',
                        value: 'QrCodeScannerOutlined',
                    },
                },
            ],
        },
        {
            _type: 'image',
            _id: 'barcode-img',
            _source: {
                type: 'field',
                value: 'car-ocr.barcode-img',
            },
        },
        // {
        //     type: 'image',
        //     subtype: 'ocr-closeup',
        //     id: 'barcode-closeup-img',
        //     source: {
        //         type: 'redux',
        //         value: 'car-ocr.barcode-img',
        //     },
        // },
        {
            _type: 'form',
            _id: 'car-photos',
            _content: [
                {
                    key: 'tag1',
                    type: 'selection',
                    subtype: 'multiple',
                    properties: ['r', 'v', 'e'],
                    placeholder: 'בחר תגית מהרשימה / הזן ידנית',
                    value: [
                        {
                            id: 1,
                            key: 'ימין',
                            label: 'ימין',
                        },
                    ],
                    prerequisite: {
                        type: 'field',
                        value: 'car-photos.photo1',
                    },
                },
                {
                    key: 'photo1',
                    type: 'file',
                    properties: ['r', 'v', 'e'],
                    icon: {
                        type: 'field',
                        value: 'car-photos.photo1',
                        defaultValue: 'CameraAltOutlined',
                    },
                },
                {
                    key: 'tag2',
                    type: 'selection',
                    subtype: 'multiple',
                    properties: ['r', 'v', 'e'],
                    placeholder: 'בחר תגית מהרשימה / הזן ידנית',
                    value: [
                        {
                            id: 1,
                            key: 'ימין',
                            label: 'ימין',
                        },
                    ],
                    prerequisite: {
                        type: 'field',
                        value: 'car-photos.photo2',
                    },
                },
                {
                    key: 'photo2',
                    type: 'file',
                    properties: ['r', 'v', 'e'],
                    icon: {
                        type: 'field',
                        value: 'car-photos.photo2',
                        defaultValue: 'CameraAltOutlined',
                    },
                },
                {
                    key: 'tag3',
                    type: 'selection',
                    subtype: 'multiple',
                    properties: ['r', 'v', 'e'],
                    placeholder: 'בחר תגית מהרשימה / הזן ידנית',
                    value: [
                        {
                            id: 1,
                            key: 'ימין',
                            label: 'ימין',
                        },
                    ],
                    prerequisite: {
                        type: 'field',
                        value: 'car-photos.photo3',
                    },
                },
                {
                    key: 'photo3',
                    type: 'file',
                    properties: ['r', 'v', 'e'],
                    icon: {
                        type: 'field',
                        value: 'car-photos.photo3',
                        defaultValue: 'CameraAltOutlined',
                    },
                },
            ],
        },
        {
            _id: 'form__email',
            _type: 'form',
            _content: [
                {
                    key: 'email',
                    type: 'email',
                    label: 'Email',
                    properties: ['r', 'v', 'e'],
                },
            ],
        },
        {
            _id: 'form__location',
            _type: 'form',
            _content: [
                {
                    key: 'location-manual',
                    type: 'map',
                    properties: ['r', 'v', 'e'],
                },
                {
                    key: 'location-auto',
                    type: 'location',
                    properties: ['r', 'v', 'e'],
                    icon: {
                        type: 'literal',
                        value: 'LocationOnOutlined',
                    },
                },
            ],
        },
        {
            _type: 'submit',
            _icon: {
                type: 'literal',
                value: 'SendOutlined',
            },
            _content: ['car-ocr', 'car-photos'],
            _id: 'submit',
        },
    ],
};

export default page;
