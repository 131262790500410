import { useEffect, useRef } from 'react';
import WebSocketSingleton, { MessageBody } from './WebSocketSingleton';
import { Operation } from '../../types';

function useWebSocket() {
    const ws = useRef<WebSocketSingleton>();

    useEffect(() => {
        ws.current = WebSocketSingleton.getInstance();
        // return () => {
        //     ws.current?.close();
        // };
    }, []);

    const sendMessage = (url: string, operation: Operation, body?: MessageBody, id = '') => {
        return ws.current?.send(url, operation, body, id);
    };
    return { sendMessage };
}

export default useWebSocket;
