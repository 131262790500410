import { CFormTextarea } from '@coreui/react-pro';
import { useSource } from '../../../utils';

const TextArea = (props) => {

  const val = useSource(props.source);

    return <CFormTextarea {...props} rows={4} placeholder={props.placeholder} value={val} />;
};

export default TextArea;
