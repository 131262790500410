import { useTranslation } from 'react-i18next';
import { useSource } from '../../../utils';
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import { newlineStyle } from '../../../views/pages/union/ClientViewt';
import { FieldComponentProps } from '../Field';

const RepairCostField = (props: FieldComponentProps) => {
    const { t } = useTranslation();
    const value = useSource(props.field._source);

    return (
        <div>
            <CFormLabel htmlFor="repairCost" className="mb-0">
                {t(props.field._label ?? 'עלות תיקון')}
            </CFormLabel>
            <div style={{ ...newlineStyle }}></div>
            <CFormInput
                style={{ textAlign: 'center', padding: '14px 10px', borderRadius: '8px', backgroundColor: 'white', border: '2px ridge black' }}
                type="number"
                id="repairCost"
                name="עלות תיקון"
                placeholder={'עלות תיקון: ' + value + " ש''ח"}
            />
        </div>
    );
};

export default RepairCostField;
