import { AppAside, AppFooter, AppHeader, AppSidebar } from '../../components/index';

type DefaultLayoutProps = {
    header?: boolean;
    children?: JSX.Element | JSX.Element[];
    sideMenu?: boolean;
    footer?: boolean;
};

const DefaultLayout = (props: DefaultLayoutProps): JSX.Element => {
    const sideMenu = props.sideMenu ?? true;
    const footer = props.footer ?? true;
    const header = props.header ?? true;

    return (
        <>
            {sideMenu && <AppSidebar />}
            <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
                {header && <AppHeader />}
                <div id="children-root" className="body flex-grow-1 px-3" style={{ flex: 1, position: 'relative' }}>
                    {props.children}
                </div>
                {footer && <AppFooter />}
            </div>
            <AppAside />
        </>
    );
};

export default DefaultLayout;
