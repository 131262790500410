import { cilDataTransferDown, cilOptions } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CChart } from '@coreui/react-chartjs';
import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CRow, CWidgetStatsA } from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWebSocket from '../../services/websocket/useWebSocket';
import { getWidgetPath } from '../../utils';
import { getToken } from '../../utils/getters/getToken';
import { WidgetProps } from '../page/Page';

export interface SummaryData {
    bgColor: string;
    title: string;
    summarization: number;
    data?: number[];
}

const getColor = (color: string) => {
    // Colors are a bit crude in the free template, but it will be better in the pro version
    if (color.includes('green')) {
        return 'success';
    } else if (color.includes('red')) {
        return 'danger';
    } else if (color.includes('orange')) {
        return 'warning';
    } else if (color.includes('purple')) {
        return 'indigo';
    } else if (color.includes('blue')) {
        return 'info';
    } else if (color.includes('pink')) {
        return 'link';
    }

    return 'primary';
};

const chartOptions = {
    color: 'white',
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                color: 'rgba(255, 255, 255, 0.35)',
            },
            ticks: {
                color: 'rgba(255,255,255, 0.65)',
            },
        },
        y: {
            grid: {
                color: 'rgba(255, 255, 255, 0.35)',
            },
            ticks: {
                color: 'rgba(255,255,255, 0.65)',
            },
        },
    },
};

const Summary = (props: WidgetProps) => {
    const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
    const { t } = useTranslation();

    const endpoint = getWidgetPath(props.widget);

    const { sendMessage } = useWebSocket();

    useEffect(() => {
        // Get the endpoint from dataRef
        sendMessage(endpoint, 'GET', undefined, 'summary-' + new Date().getTime())
          ?.then((responseData) => {
            if (responseData && Array.isArray(responseData)) setSummaryData(responseData);
          });
    }, []);

    return (
        <CRow>
            {summaryData.map((summary: SummaryData) => (
                <CCol
                    key={summary.title}
                    sm={6}
                    lg={3}
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.5) !important',
                    }}
                >
                    <CWidgetStatsA
                        className="mb-4"
                        color={getColor(summary.bgColor)}
                        value={<>{t(summary.title) ?? summary.title}</>}
                        title={summary.summarization}
                        action={
                            <CDropdown alignment="end">
                                <CDropdownToggle color="transparent" caret={false} className="p-0">
                                    <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem style={{ cursor: 'pointer' }}>
                                        {t('Download')} <CIcon icon={cilDataTransferDown} size="lg" />{' '}
                                    </CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        }
                        chart={
                            <CChart
                                type="line"
                                color="white"
                                className="mt-3 mx-3"
                                style={{
                                    transition: 'all 0.3s ease',
                                }}
                                data={{
                                    // Get the data from the dashboardJson
                                    labels: ['January', 'February', 'March', 'April'],
                                    datasets: [
                                        {
                                            label: t(summary.title) ?? summary.title,
                                            backgroundColor: 'rgba(220, 220, 220, 0.2)',
                                            borderColor: 'rgba(220, 220, 220, 1)',
                                            pointBackgroundColor: 'rgba(220, 220, 220, 1)',
                                            pointBorderColor: '#fff',
                                            data: summary.data ?? [],
                                        },
                                    ],
                                }}
                                options={chartOptions}
                            />
                        }
                    ></CWidgetStatsA>
                </CCol>
            ))}
        </CRow>
    );
};

export default Summary;
