import _ from 'lodash';
import { useEffect, useState } from 'react';
import { performOCR } from '../services/ocr/getLicensePlate';
import useAppSelect from '../services/redux/useAppSelect';
import useWebSocket from '../services/websocket/useWebSocket';
import { Source, Transform } from '../types';

const _transformData = async (transformType: Transform | undefined, data: object | undefined) => {
    if (!transformType) {
        return data;
    }
    if (!data && !transformType) {
        return undefined;
    }

    if (transformType === 'ocr') {
        if (typeof data === 'string') {
            const res = await performOCR(data);
            return res;
        } else if (typeof data === 'object' && Object.keys(data).includes('base64')) {
            const res = await performOCR(data?.base64);
            return res?.digits;
        }

        return data;
    }

    return data;
};

export const useSource = (source?: Source) => {
    const [value, setValue] = useState(null);
    const runtime = useAppSelect('runtime');
    const { sendMessage } = useWebSocket();

    useEffect(() => {
        if (source?.type !== 'field') {
            return;
        }

        getSource().then(result => {
            setValue(result);
        });
    }, [Object.keys(runtime).length]);

    useEffect(() => {
        getSource().then(result => {
            setValue(result);
        });
    }, []);

    const getSource = async () => {
        let result;

        if (!source) {
            result = undefined;
        } else if (typeof source === 'string') {
            // Source has been given inline and should not be processed
            result = source;
        } else if (source.type === 'literal') {
            result = source.value;
        } else if (source.type === 'field') {
            result = _.get(runtime, source.value) ?? source.defaultValue ?? null;
        } else if (source.type === 'url') {
            result = new Promise(resolve => {
                sendMessage(source.value, 'GET').then(response => {
                    resolve(response);
                    setValue(response);
                });
            });
        }

        console.groupEnd();
        return result;
    };

    return { getSource, value };
};
