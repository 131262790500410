import { CDateRangePicker } from '@coreui/react-pro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DateRangePicker = props => {
    const { t } = useTranslation();
    const getOneWeekAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 7); // Subtract 7 days
        date.setHours(0, 0, 0, 0); // Set to start of the day
        return date;
    };

    const getEndOfToday = () => {
        const date = new Date();
        date.setHours(23, 59, 59, 999); // Set to end of the day
        return date;
    };

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    React.useEffect(() => {
        // If a value is available, set the state
        // Otherwise, use the default value
        if (props.value) {
            const [start, end] = props.value.split('-');
            setStartDate(new Date(start));
            setEndDate(new Date(end));
            props.onChange(`${startDate}-${endDate}`);
        } else {
            setStartDate(getOneWeekAgo());
            setEndDate(getEndOfToday());
            props.onChange(`${getOneWeekAgo()}-${getEndOfToday()}`);
        }
    }, []);

    const handleStartDateChange = newDate => {
        setStartDate(newDate);
        // Additional logic if needed
        props.onChange(`${startDate}-${endDate}`);
    };

    const handleEndDateChange = newDate => {
        setEndDate(newDate);
        // Additional logic if needed
        props.onChange(`${startDate}-${endDate}`);
    };

    return (
        <>
            <CDateRangePicker
                firstDayOfWeek={0}
                startDate={startDate}
                endDate={endDate}
                format="dd/MM/yyyy"
                label={`${t(props.config._label) + (props.config._required ? ' *' : '')}`}
                onStartDateChange={date => handleStartDateChange(date)}
                onEndDateChange={date => handleEndDateChange(date)}
                // Add other props as required
            />
        </>
    );
};

export default DateRangePicker;
