import { useMediaQuery } from '@mui/material';
import { CSSProperties } from 'react';

type CssValueType = string | number;

type ResponsiveStyleProps = {
    [P in keyof CSSProperties]:
        | CssValueType
        | {
              mobile: CssValueType;
              tablet?: CssValueType;
              laptop?: CssValueType;
              desktop?: CssValueType;
          };
};

/**
 * useResponsiveStyles - A hook to get responsive styles based on screen size.
 *
 * @param styles - An object of CSS properties. For responsive styles, provide an object with keys as:
 *                 'mobile', 'tablet', 'laptop', and/or 'desktop'. If a specific screen size doesn't have a
 *                 defined style, it will "fall back" to the biggest defined size that's smaller than the
 *                 current screen size.
 * @returns - A CSSProperties object with the appropriate styles for the current screen size.
 */
const useResponsiveStyles = (styles: ResponsiveStyleProps): React.CSSProperties => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 960px)');
    const isLaptop = useMediaQuery('(min-width: 961px) and (max-width: 1280px)');
    const isDesktop = useMediaQuery('(min-width: 1281px)');

    const appliedStyles: React.CSSProperties = {};

    Object.entries(styles).forEach((key, ind) => {
        const [property, values] = key as [keyof CSSProperties, any];

        if (typeof values === 'object' && !Array.isArray(values)) {
            // Apply responsive styles based on current screen size and fallback to smaller sizes if not available.

            if (isMobile) {
                (appliedStyles as any)[property] = values.mobile;
            } else if (isTablet) {
                (appliedStyles as any)[property] = values.tablet ?? values.mobile;
            } else if (isLaptop) {
                (appliedStyles as any)[property] = values.laptop ?? values.tablet ?? values.mobile;
            } else if (isDesktop) {
                (appliedStyles as any)[property] = values.desktop ?? values.laptop ?? values.tablet ?? values.mobile;
            }
        } else {
            (appliedStyles as any)[property] = values as CssValueType;
        }
    });

    return appliedStyles;
};

export default useResponsiveStyles;
