import { CCloseButton, CSidebar, CSidebarHeader } from '@coreui/react-pro';
import React from 'react';

import { CSidebarProps } from '@coreui/react-pro/dist/components/sidebar/CSidebar';
import { setAsideShow } from '../services/redux/globalStateManager';
import useAppDispatch from '../services/redux/useAppDispatch';
import useAppSelect from '../services/redux/useAppSelect';
import RtlAdjustHorizontal from './RtlAdjustHorizontal';

const AppAside = () => {
    const dispatch = useAppDispatch();
    const asideShow = useAppSelect('asideShow');
    const isRtl = useAppSelect('rtl');

    const sidebarProps: CSidebarProps = {
        colorScheme: 'dark',
        size: 'lg',
        overlaid: true,
        visible: asideShow,
        onVisibleChange: (visible) => {
            dispatch(setAsideShow(visible));
        },
    };

    return (
        <RtlAdjustHorizontal>
            <CSidebar {...sidebarProps} placement={isRtl ? 'start' : 'end'}>
                <CSidebarHeader className="bg-transparent">
                    <CCloseButton
                        white={!document.documentElement.className.includes('sidebar-light')}
                        className="float-end"
                        onClick={() => dispatch(setAsideShow(false))}
                    />
                </CSidebarHeader>
            </CSidebar>
        </RtlAdjustHorizontal>
    );
};

export default React.memo(AppAside);
