import { CButton, CCard, CCollapse } from '@coreui/react-pro';
import { useState } from 'react';
import useAppSelect from '../services/redux/useAppSelect';
import NavButton from './header-nav/NavButton';
import Stack from './layout-util/stack';

const userIconAlt = 'User icon';

const UserIcon = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const dropdownButtons = useAppSelect('dropdownButtons');
    const user = localStorage.getItem('user');

    if (!user) {
        console.warn('No user data has been saved.');

        return null;
    }

    // If image is undefined, load a placeholder from uplash.
    const defaultImg = (
        <img
            alt={userIconAlt}
            style={{
                borderRadius: '50%',
                width: '2.5rem',
                height: '2.5rem',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
            width={'100%'}
            height={'100%'}
            src="/user.png"
        />
    );
    let img;

    try {
        const image = JSON.parse(user)?.image;

        // Load an img element, based on wether image is http or base64.

        if (image) {
            img = image.mode === 'http' ? <img alt={userIconAlt} src={image.data} /> : <img alt={userIconAlt} src={`data:image/png;base64,${image.data}`} />;
        }

        if (!image || !image?.data) {
            img = defaultImg;
        }
    } catch (e) {
        img = defaultImg;
    }

    const handlePopoverOpen = event => {
        setAnchorEl(event?.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Stack onMouseLeave={handlePopoverClose} onMouseEnter={handlePopoverOpen} direction="column" style={{ position: 'relative' }}>
            <Stack direction="row" style={{ gap: 3 }}>
                <CButton
                    onClick={e => {
                        if (open) {
                            handlePopoverClose();
                        } else {
                            handlePopoverOpen(e);
                        }
                    }}
                    variant="ghost"
                    color="dark"
                >
                    {img}
                </CButton>
            </Stack>
            <div
                style={{
                    width: '180px',
                    position: 'absolute',
                    background: '#1d1d1d',
                    left: 0,
                    top: '100%',
                    borderRadius: '14px',
                    marginLeft: '-3rem',
                }}
            >
                <CCollapse visible={open}>
                    <CCard className="py-2" style={{ borderRadius: '4px' }}>
                        {dropdownButtons.map(button => (
                            <NavButton key={button} name={button} role="dropdown" narrow={false} />
                        ))}
                    </CCard>
                </CCollapse>
            </div>
        </Stack>
    );
};

export default UserIcon;
