import { staticRoutes } from '../../configuration/constants';
import { getToken } from '../../utils/getters/getToken';
import { Message } from './WebSocketSingleton';

export const formatMessage = (message: Partial<Message>): Message | undefined => {
    if (!message.url) {
        console.error('Message must have a url');
        return undefined;
    }

    const formattedMessage: Message = {
        id: message.id ?? message.url,
        url: message.url,
        operation: message.operation ?? 'GET',
    };

    if (message.body) {
        formattedMessage.body = message.body;
    }

    if (!window.location.pathname.startsWith(staticRoutes.systemInitialization)) {
        formattedMessage.token = getToken();
    }

    return formattedMessage;
};
