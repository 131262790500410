import { useTranslation } from 'react-i18next';
import Label from '../../form/Label';
import { FieldComponentProps } from '../Field';

const Radio = (props: FieldComponentProps) => {
    const { t } = useTranslation();

    const options = props.options ?? [];

    // Display the options and mark the current value as checked
    return (
        <div className="form-check" style={{ display: 'flex', flexDirection: 'column' }}>
            <Label required={props.config.__required} className="form-label">
                {t(props.field._label ?? '')}
            </Label>
            {options.map((option, ind) => (
                <label style={{ cursor: 'pointer' }} className="form-check-label" htmlFor={option.label} key={option.label}>
                    <input className="form-check-input" type="radio" id={option.label} value={option._id} checked={props.value === String(option._id)} onChange={props.onChange} />
                    {t(option.label ?? option._label) ?? option.label}
                </label>
            ))}
        </div>
    );
};

export default Radio;
