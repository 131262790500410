export function isDateFormatPattern(pattern: string): boolean {
    if (!pattern.trim()) return false;
    // This regex pattern identifies common date format components (D, M, Y),
    // and allows for any separator.
    const dateFormatRegex = /^([D]{1,2}|[M]{1,2}|[Y]{2,4}|[^a-zA-Z0-9])+$/;

    // Count the number of separators
    const separatorCount = pattern.split('').filter((char) => !/[DMY]/.test(char)).length;

    // Check for adjacent separators
    const adjacentSeparators = /[^a-zA-Z0-9]{2,}/.test(pattern);

    return dateFormatRegex.test(pattern) && separatorCount <= 2 && !adjacentSeparators;
}

// Edge case tests
const edgeCases = [
    '',
    ':',
    ',,',
    '::',
    'D',
    'M',
    'Y',
    'DDDD',
    'MMMM',
    'YYYY',
    '::',
    '--',
    '..',
    ':-',
    '-:',
    'D:M:Y',
    'D-M-Y',
    'D.M.Y',
    'D:-M',
    'D-.-Y',
    'M:D',
    'Y:M:D',
    'Y-M-D',
    'D:M:Y:Z',
    'D-M-Y-F',
    'D:M:Y:F',
    'D@M',
    'D!M!Y',
    'D:M@Y',
    'D!M-Y',
    'DD:MM',
    'DD:YY',
    'MM:YY',
    'YY:DD',
    'YY:DD:MM',
];

if (require.main === module) {
    // Run this file directly to test edge cases
    edgeCases.forEach((pattern) => {
        console.log(`Pattern: ${pattern}, Is Date Format: ${isDateFormatPattern(pattern)}`);
    });
}

/**
 * Output:
 *  [LOG]: "Pattern: , Is Date Format: false" 
    [LOG]: "Pattern: :, Is Date Format: true" 
    [LOG]: "Pattern: ,,, Is Date Format: false" 
    [LOG]: "Pattern: ::, Is Date Format: false" 
    [LOG]: "Pattern: D, Is Date Format: true" 
    [LOG]: "Pattern: M, Is Date Format: true" 
    [LOG]: "Pattern: Y, Is Date Format: false" 
    [LOG]: "Pattern: DDDD, Is Date Format: true" 
    [LOG]: "Pattern: MMMM, Is Date Format: true" 
    [LOG]: "Pattern: YYYY, Is Date Format: true" 
    [LOG]: "Pattern: ::, Is Date Format: false" 
    [LOG]: "Pattern: --, Is Date Format: false" 
    [LOG]: "Pattern: .., Is Date Format: false" 
    [LOG]: "Pattern: :-, Is Date Format: false" 
    [LOG]: "Pattern: -:, Is Date Format: false" 
    [LOG]: "Pattern: D:M:Y, Is Date Format: false" 
    [LOG]: "Pattern: D-M-Y, Is Date Format: false" 
    [LOG]: "Pattern: D.M.Y, Is Date Format: false" 
    [LOG]: "Pattern: D:-M, Is Date Format: false" 
    [LOG]: "Pattern: D-.-Y, Is Date Format: false" 
    [LOG]: "Pattern: M:D, Is Date Format: true" 
    [LOG]: "Pattern: Y:M:D, Is Date Format: false" 
    [LOG]: "Pattern: Y-M-D, Is Date Format: false" 
    [LOG]: "Pattern: D:M:Y:Z, Is Date Format: false" 
    [LOG]: "Pattern: D-M-Y-F, Is Date Format: false" 
    [LOG]: "Pattern: D:M:Y:F, Is Date Format: false" 
    [LOG]: "Pattern: D@M, Is Date Format: true" 
    [LOG]: "Pattern: D!M!Y, Is Date Format: false" 
    [LOG]: "Pattern: D:M@Y, Is Date Format: false" 
    [LOG]: "Pattern: D!M-Y, Is Date Format: false" 
    [LOG]: "Pattern: DD:MM, Is Date Format: true" 
    [LOG]: "Pattern: DD:YY, Is Date Format: true" 
    [LOG]: "Pattern: MM:YY, Is Date Format: true" 
    [LOG]: "Pattern: YY:DD, Is Date Format: true" 
    [LOG]: "Pattern: YY:DD:MM, Is Date Format: true" 

 */
