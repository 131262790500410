import { CFormCheck } from '@coreui/react-pro';
import React from 'react';
import { Model } from '../../../services/redux/globalStateManager';
import { ViewContext } from '../../page/Page';
import { FieldComponentProps } from '../Field';

interface SelectableListProps {
    source: Model[];
}

const SelectableList: React.FC<FieldComponentProps> = props => {
    const [items, setItems] = React.useState<Model[]>([]);
    const [selectedItemId, setSelectedItemId] = React.useState(null);

    const { isValid, setValidity } = React.useContext(ViewContext);

    const handleCheckboxChange = itemId => {
        setSelectedItemId(itemId);
    };

    React.useEffect(() => {
        props.onChange && props.onChange(items[selectedItemId]);
    }, [selectedItemId]);

    React.useEffect(() => {
        setValidity(selectedItemId !== null);
    }, [selectedItemId]);

    React.useEffect(() => {
        if (props.config.__source.type === 'inline') {
            setItems(props.config.__source.value);
        }
    }, [props.source]);

    return (
        <div style={{ border: '2px ridge black', borderRadius: '16px', backgroundColor: 'white', padding: '40px', marginTop: '50px' }}>
            <p>שליפת מספרי פניות - בחר פניה:</p>
            {items?.map((item, ind) => (
                <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label htmlFor={item.id}>
                        {ind + 1}. <span className="mx-4">{item?.name || item.number}</span>
                    </label>
                    <CFormCheck id={item.id} checked={selectedItemId === ind} onChange={() => handleCheckboxChange(ind)} />
                </div>
            ))}
        </div>
    );
};

export default SelectableList;
