import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useTranslation } from 'react-i18next';
import { updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { downloadTable } from '../../utils';
import Stack from '../layout-util/stack';
import { formStatePrefix } from '../form/Field';

const BaseTableHeader = ({ title, id }) => {
    const { t } = useTranslation();
    const runtime = useAppSelect('runtime');
    const dispatch = useAppDispatch();
    const rows = runtime[id]?.rows ?? [];

    const handleAddButton = () => {
        const currentMode = runtime[id]?.mode;
        if (currentMode === 'view') {
            dispatch(updateRuntimeData(`${id}.mode`, 'CREATE')); // Will tell the table to display the create form
            dispatch(updateRuntimeData(`${id}.activeRow`, {}));
            dispatch(updateRuntimeData(`${id}.modal`, { title: `Add new ${title}` }));
            dispatch(updateRuntimeData(`${formStatePrefix}.${id}.`, {}));
        } else {
            dispatch(updateRuntimeData(`${id}.mode`, 'view')); // Will tell the table to display the view mode
        }
    };

    return (
        <Stack direction="row" style={{ justifyContent: 'space-between' }}>
            <Stack direction="row">
                <h1>{t(title?.toLowerCase() ?? '')}</h1>
                <CDropdown variant="dropdown">
                    <CDropdownToggle color="primary">{t('Download') + ' ' + t('Data') + ' '}</CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem onClick={() => downloadTable(rows, 'excel')}>{t('Excel')}</CDropdownItem>
                        <CDropdownItem onClick={() => downloadTable(rows, 'pdf')}>{t('PDF')}</CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </Stack>

            <CButton type="button" color="primary" onClick={handleAddButton}>
                {`${t('Add')} ${t(title?.toLowerCase() ?? '')}`}
            </CButton>
        </Stack>
    );
};

export default BaseTableHeader;
