import React, { ReactNode } from 'react';

interface SectionProps {
    children: ReactNode;
}

const Section: React.FC<SectionProps> = ({ children }) => (
    <section style={{ paddingTop: '1.15rem', paddingBottom: '1.15rem' }}>{children}</section>
);

export default Section;
 
