export const getGeocode = (loc: Location): Promise<google.maps.GeocoderResult[]> => {
    if (!window.google) {
        return Promise.reject('Google map not found');
    }

    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode(
            {
                location: {
                    lat: loc.lat,
                    lng: loc.lng,
                },
            },
            (results, status) => {
                if (status === 'OK') {
                    if (results?.length) {
                        resolve(results);
                    } else {
                        reject('No results found');
                    }
                } else {
                    reject(status);
                }
            }
        );
    });
};
