import React from 'react';

interface LogoProps {
    width?: number;
    height?: number;
    style?: React.CSSProperties;
}

const Logo: React.FC<LogoProps> = () => (
    <img
        src="/betec.png"
        width={70}
        alt="betec official logo"
        style={{
            marginTop: '0.75rem',
            marginRight: '-0.125rem',
            marginLeft: '-0.125rem',
        }}
    />
);

export default Logo;
 
