/**
 * Capitalize the first letter of a string
 * @param str to capitalize
 * @returns the same string with the first letter uppercased
 */
export function _capitalize(str: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Makes a string singular if it's plural
 * @param str - input string
 * @returns the singular form of the string
 */
export const singular = (str?: string): string => {
    if (!str) return '';

    if (str.endsWith('ies')) {
        return str.slice(0, str.length - 3) + 'y';
    }

    return str.toLowerCase().endsWith('s') ? str.slice(0, str.length - 1) : str;
};
