import { Source } from '../../types';
import { useSource } from '../../utils';

const Text = (props: { title: string; source: Source }) => {
    const { title, source } = props;

    // const { handleSource } = useSource();
    const dynamicValue = useSource(source);

    // Render logic based on 'type'
    // For now, let's assume it's just a simple display
    return (
        <div>
            <pre>{JSON.stringify(props, null, 1)}</pre>
            <h1>{title}</h1>
            {/* <p>{dynamicValue}</p> */}
        </div>
    );
};

export default Text;
